import "./bin-management-import-instructions-modal.scss";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "../../../utils/download-file";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";

interface ImportBinManagementInstructionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  validateBinInventory: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImportBinManagementInstruction = ({
  open,
  setOpen,
  validateBinInventory,
}: ImportBinManagementInstructionProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { openSnackBarMessage } = useSnackBar();
  const { post } = useAxios();

  const downloadTemplate = (): void => {
    const postData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/bins/csv/inventory/template",
        data: null,
      });
      downloadFile(data, "bin-inventory-template.csv");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Import Bin Inventory Instructions
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='responsive-dialog__text'>
          Select a CSV file to import bin inventories with the following headers:
          <br />
          <span className='responsive-dialog__text-bold'>bin, inventory, date</span>
          <br />
          Where <span className='responsive-dialog__text-bold'>bin</span> refers to the bin being updated,{" "}
          <span className='responsive-dialog__text-bold'>inventory</span> is the new inventory in (MT),{" "}
          <span className='responsive-dialog__text-bold'>date</span> is the date the inventory reading was taken.{" "}
          <span className='responsive-dialog__text-bold'>Note: date</span> cannot be in the future.
          <br />
          <Button variant='outlined' startIcon={<FileDownloadIcon />} sx={{ mt: 2 }} onClick={downloadTemplate}>
            Download Template
          </Button>
        </DialogContentText>
      </DialogContent>

      <Button
        variant='outlined'
        component='label'
        startIcon={<AttachFileIcon />}
        className='responsive-dialog__import-button'
      >
        Select...
        <input type='file' accept='.csv' onChange={validateBinInventory} hidden />
      </Button>
    </Dialog>
  );
};
export default ImportBinManagementInstruction;
