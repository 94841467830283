import { useParams } from "react-router-dom";
import { Header } from "../../components/layout/header/header";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { BinInventoryHistoryTable } from "./bin-inventory-history-table/bin-inventory-history-table";
import { IconButton, Tooltip } from "@mui/material";
import { DownloadOutlined } from "@mui/icons-material";
import { downloadFile } from "../../utils/download-file";

const headers = ["Bin", "Datetime", "Inventory"];

export function BinInventoryHistory() {
  const { id } = useParams();

  const { get } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const exportBinHistoryData = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await get<BlobPart>({
        url: `/client/bins/${id}/inventory/export`,
      });
      downloadFile(data, "bin-history-data.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  return (
    <section className='bin-inventory-history'>
      <Header>
        <Tooltip title='Download History Data'>
          <IconButton onClick={exportBinHistoryData}>
            <DownloadOutlined fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <BinInventoryHistoryTable headers={headers} />
    </section>
  );
}
