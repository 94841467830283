import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./drivers-table.scss";
import { Driver } from "../../../../interfaces";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Dispatch, SetStateAction } from "react";

interface DriversTableProps {
  headers: string[];
  rows: Driver[] | [];
  loading: boolean;
  setDrivers: Dispatch<SetStateAction<Driver[]>>;
  onAddChanged: (row: Driver, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function DriversTable({
  headers,
  rows,
  loading,
  setDrivers,
  onAddChanged,
  isUnsavedChanges,
}: DriversTableProps): JSX.Element {
  const onSaveChanges = (row: Driver): void => {
    const data = [...rows];
    data.map((driver) => {
      if (driver.id === row.id) {
        driver = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setDrivers(data);
  };

  const removeDriver = (row: Driver): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((driver) => driver.id !== copyRow?.id);
      setDrivers(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='drivers-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.firstName}
              property='firstName'
              name='First Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.lastName}
              property='lastName'
              name='Last Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button variant='contained' color='error' startIcon={<CloseOutlined />} onClick={() => removeDriver(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
