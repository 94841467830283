import { Header } from "../../components/layout/header/header";
import { BinManagementTable } from "./bin-management-table/bin-management-table";
import { Footer } from "../../components/layout/footer/footer";
import { BinManagementType, CompanyUnit } from "../../interfaces/index";
import { useState, useEffect, useContext, ChangeEvent } from "react";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { Tooltip, IconButton } from "@mui/material";
import ImportBinManagementInstruction from "./bin-management-import-instructions-modal/bin-management-import-instructions-modal";
import { UnitContext } from "../../contexts/unit-context";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { getFileFormData } from "../../utils/append-file";
import ValidationModal from "../../components/validation-modal/validation-modal";

export function BinManagement(): JSX.Element {
  const [bins, setBins] = useState<BinManagementType[]>([]);
  const [loading, setLoading] = useState(false);
  const [binManagementFile, setBinManagementFile] = useState<FormData>();
  const [importBinManagementOpen, setImportBinManagementOpen] = useState(false);
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  const { unitOfMeasurement } = useContext(UnitContext);

  useEffect(() => {
    fetchBinManagement();
  }, []);

  const refresh = (): void => {
    fetchBinManagement("Successfully refreshed!");
  };

  const fetchBinManagement = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<BinManagementType[]>({
        url: "/client/bins/management",
      });

      setBins(data);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const openImportBinManagementModal = (): void => {
    setImportBinManagementOpen(true);
  };

  const validateBinInventory = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const validateData = async (): Promise<any> => {
        setBinManagementFile(formData);
        const response = await post({
          url: "/client/bins/csv/inventory/validate",
          data: formData,
        });
        return response;
      };

      validateData()
        .then((response) => {
          if (response?.numberOfRecordsPriorToMostRecentInventory > 0) {
            setImportBinManagementOpen(false);
            setValidationState({
              error: `Warning: Import contains ${response.numberOfRecordsPriorToMostRecentInventory} 
            bin inventory records that are prior to last bin inventory record, these records 
            will have no impact on the feed forecast are you sure you want to proceed.`,
              modalState: true,
            });
          } else {
            uploadBins(formData);
            setImportBinManagementOpen(false);
          }
        })
        .catch((error) => {
          openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
          setValidationState({ error: "", modalState: false });
        });
    }
  };

  const uploadBins = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      await post<BinManagementType[]>({
        url: "/client/bins/csv/inventory",
        data: formData || binManagementFile,
      });

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData()
      .then(() => fetchBinManagement())
      .catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setValidationState({ error: "", modalState: false });
      });
  };

  const headers = [
    "Name",
    "Site",
    "Barn",
    `Volume ${unitOfMeasurement === CompanyUnit.Imperial ? "(Cubic feet)" : "(hL)"}`,
    `Inventory ${unitOfMeasurement === CompanyUnit.Imperial ? "(T)" : "(MT)"}`,
    "Date",
    "Update",
  ];

  return (
    <section className='bin-management'>
      <Header>
        <Tooltip title='Import Data'>
          <IconButton onClick={openImportBinManagementModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <BinManagementTable headers={headers} rows={bins} fetchBinManagement={fetchBinManagement} loading={loading} />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh Bin Management' />

      <ImportBinManagementInstruction
        open={importBinManagementOpen}
        setOpen={setImportBinManagementOpen}
        validateBinInventory={validateBinInventory}
      />

      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadBins()}
      />
    </section>
  );
}
