import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { ChangeEvent, useEffect, useState } from "react";
import { Truck } from "../../../interfaces";
import { generatedId } from "../../../utils/generator-ids";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";
import { TrucksTable } from "./trucks-table/trucks-table";
import ImportTrucksInstruction from "./import-trucks-instructions-modal/import-trucks-instructions-modal";

export function SetupTrucks(): JSX.Element {
  const headers = ["Name", "Description", "Capacity in MT", "Remove"];

  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [changedRows, setChangedRows] = useState<Truck[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importTrucksOpen, setImportTrucksOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [trucksFile, setTrucksFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchTrucks();
  }, []);

  const refresh = (): void => {
    fetchTrucks("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchTrucks("Successfully cancelled!");
  };

  const fetchTrucks = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Truck[]>({ url: "/client/trucks" });

      setTrucks(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateTrucks = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setTrucksFile(formData);
        await post({
          url: "/client/trucks/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadTrucks(formData);
          setImportTrucksOpen(false);
        })
        .catch((error) => {
          setImportTrucksOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadTrucks = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<Truck[]>({
        url: "/client/trucks/csv",
        data: formData || trucksFile,
      });
      importTrucks(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: Truck, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setChangedRows(data as any[]);
  };

  const add = (): void => {
    const data = [...trucks];
    const trucksData = [...changedRows];

    const truck: Truck = {
      id: generatedId(36),
      name: "Name",
      description: "Description",
      capacity: 0,
      toDelete: false,
      isAdded: true,
    };

    truck.subId = truck.id;
    data.push(truck);
    trucksData.push(truck);

    setIsReadyForSave(false);
    setTrucks(data);
    setChangedRows(trucksData);
  };

  const importTrucks = (importedTrucks: Truck[]): void => {
    let data = [...trucks];
    let trucksData = [...changedRows];
    importedTrucks = importedTrucks.map((truck) => {
      truck.id = generatedId(36);
      truck.subId = truck.id;
      truck.isAdded = true;
      truck.toDelete = false;
      return truck;
    });

    data = data.concat(importedTrucks);
    trucksData = trucksData.concat(importedTrucks);

    setIsReadyForSave(false);
    setTrucks(data);
    setChangedRows(trucksData);
  };

  const save = (): void => {
    getFinalTrucks();

    const postData = async (): Promise<void> => {
      await post<Truck[]>({ url: "/client/trucks/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchTrucks("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((truck) => {
      if (truck.isAdded && !truck?.id) {
        if (truck.subId) {
          truck.id = truck.subId;
        }
      }

      return truck;
    });

    setChangedRows(data);
  };

  const getFinalTrucks = (): void => {
    let data = [...changedRows];
    data = data.map((truck) => {
      if (truck.isAdded) {
        truck.id = null;
      }
      return truck;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportTrucksModal = (): void => {
    setImportTrucksOpen(true);
  };

  const handleSetupTrucksComplete = (): void => {
    handleSetupComplete("TRUCKS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import Setup Trucks'>
          <IconButton onClick={openImportTrucksModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleSetupTrucksComplete}>
          Setup Complete
        </Button>
      </Header>

      <TrucksTable
        headers={headers}
        rows={trucks}
        setTrucks={setTrucks}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save System Trucks'
        addButtonLabel='Add System Trucks'
        refreshButtonLabel='Refresh System Trucks'
        cancelButtonLabel='Discard changes'
      />

      <ImportTrucksInstruction open={importTrucksOpen} setOpen={setImportTrucksOpen} uploadTrucks={validateTrucks} />

      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadTrucks()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
