import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { CompanyUnit, Unit } from "../interfaces";
import { useAxios } from "../hooks";
import { useSnackBar } from "../providers";

export interface IUnitContext {
  unitOfMeasurement: CompanyUnit | undefined;
  loading: boolean;
  setUnitOfMeasurement: React.Dispatch<React.SetStateAction<CompanyUnit | undefined>>;
}

const UnitContext = createContext({} as IUnitContext);

const UnitContextProvider = ({ children }: PropsWithChildren) => {
  const [unitOfMeasurement, setUnitOfMeasurement] = useState<CompanyUnit>();
  const [loading, setLoading] = useState(false);

  const { get } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const fetchCompany = (): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Unit>({ url: "/client/companies" });

      setUnitOfMeasurement(data.unit);
      setLoading(false);
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const state = {
    unitOfMeasurement,
    loading,
    setUnitOfMeasurement,
  };

  return <UnitContext.Provider value={state}>{children}</UnitContext.Provider>;
};

export { UnitContextProvider, UnitContext };
