import { Button, IconButton, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import useAxios from "../../../hooks/use-axios.hook";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { useSnackBar } from "../../../providers";
import { GrowthCurve } from "../../../interfaces";
import { SetupGrowthCurvesTable } from "./setup-growth-curves-table/setup-growth-curves-table";
import ImportGrowthCurvesInstruction from "./import-growth-curve-instructions-modal/import-growth-curve-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";

export function SetupGrowthCurves(): JSX.Element {
  const headers = ["Name", "Description", "Growth Curve Sequence", "Remove"];

  const [growthCurves, setGrowthCurves] = useState<GrowthCurve[]>([]);
  const [changedRows, setChangedRows] = useState<GrowthCurve[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importGrowthCurveOpen, setImportGrowthCurveOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [growthCurvesFile, setGrowthCurvesFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });
  const [active, setActive] = useState<string | null>(null);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchGrowthCurves();
  }, []);

  const refresh = (): void => {
    fetchGrowthCurves("Successfully refreshed!");
    setActive(null);
  };

  const cancel = (): void => {
    fetchGrowthCurves("Successfully cancelled!");
  };

  const fetchGrowthCurves = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthCurve[]>({ url: "/client/growth-curves" });

      setGrowthCurves(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateGrowthCurves = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setGrowthCurvesFile(formData);
        await post({
          url: "/client/growth-curves/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadGrowthCurves(formData);
          setImportGrowthCurveOpen(false);
        })
        .catch((error) => {
          setImportGrowthCurveOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadGrowthCurves = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<GrowthCurve[]>({
        url: "/client/growth-curves/csv",
        data: formData || growthCurvesFile,
      });
      importGrowthCurves(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: GrowthCurve, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as GrowthCurve[]);
  };

  const add = (): void => {
    const data = [...growthCurves];
    const growthCurvesData = [...changedRows];

    const growthCurve: GrowthCurve = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      description: "Description",
      toDelete: false,
      isAdded: true,
    };

    growthCurve.subId = growthCurve.id;
    data.push(growthCurve);
    growthCurvesData.push(growthCurve);

    setIsReadyForSave(false);
    setGrowthCurves(data);
    setChangedRows(growthCurvesData);
  };

  const importGrowthCurves = (importedGrowthCurves: GrowthCurve[]): void => {
    let data = [...growthCurves];
    let growthCurvesData = [...changedRows];
    importedGrowthCurves = importedGrowthCurves.map((growthCurve) => {
      growthCurve.id = generatedId(36);
      growthCurve.subId = growthCurve.id;
      growthCurve.isAdded = true;
      growthCurve.toDelete = false;
      return growthCurve;
    });

    data = data.concat(importedGrowthCurves);
    growthCurvesData = growthCurvesData.concat(importedGrowthCurves);

    setIsReadyForSave(false);
    setActive(null);
    setGrowthCurves(data);
    setChangedRows(growthCurvesData);
  };

  const getFinalSetupGrowthCurves = (): void => {
    let data = [...changedRows];

    data = data.map((growthCurve) => {
      if (growthCurve.isAdded) {
        growthCurve.id = null;
      }

      return growthCurve;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupGrowthCurves();

    const postData = async (): Promise<void> => {
      await post<GrowthCurve[]>({ url: "/client/growth-curves/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      setActive(null);
      fetchGrowthCurves("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((growthCurve) => {
      if (growthCurve.isAdded && !growthCurve?.id) {
        growthCurve.id = growthCurve.subId;
      }

      return growthCurve;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
    setActive(null);
  };

  const openImportGrowthCurvesModal = (): void => {
    setImportGrowthCurveOpen(true);
  };

  const handleGrowthCurvesSetupComplete = (): void => {
    handleSetupComplete("GROWTH_CURVES", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='setup-growth-curves'>
      <Header>
        <Tooltip title='Import Setup Growth Curves'>
          <IconButton onClick={openImportGrowthCurvesModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleGrowthCurvesSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupGrowthCurvesTable
        headers={headers}
        rows={growthCurves}
        setGrowthCurves={setGrowthCurves}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
        changedRows={changedRows}
        active={active}
        setActive={setActive}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Growth Curves'
        addButtonLabel='Add Setup Growth Curves'
        refreshButtonLabel='Refresh Setup Growth Curves'
        cancelButtonLabel='Discard changes'
      />

      <ImportGrowthCurvesInstruction
        open={importGrowthCurveOpen}
        setOpen={setImportGrowthCurveOpen}
        uploadGrowthCurves={validateGrowthCurves}
      />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadGrowthCurves()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
