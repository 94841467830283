/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import "./feed-group-management-table.scss";
import { AnimalMovement, FeedGroupManagementType, Site } from "../../../interfaces";
import { useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { EditableMultipleSelectCell } from "../../../components/table/editable-multiple-select/editable-multiple-select";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import AnimalMovementModal from "../../../components/animal-movement-dialog/animal-movement-dialog";
import { useNavigate } from "react-router-dom";
import { EditableDatePickerCell } from "../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import { AnimalMovementDataModal } from "../../../components/animal-movement-data-modal/animal-movement-data-modal";

interface FeedGroupManagementTableProps {
  headers: string[];
  rows: FeedGroupManagementType[];
  loading: boolean;
  fetchFeedGroups: () => void;
  setFeedGroups: React.Dispatch<React.SetStateAction<FeedGroupManagementType[]>>;
  onAddChanges: (row: FeedGroupManagementType, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function FeedGroupManagementTable({
  headers,
  rows,
  loading,
  fetchFeedGroups,
  setFeedGroups,
  onAddChanges,
  isUnsavedChanges,
}: FeedGroupManagementTableProps): JSX.Element {
  const [sites, setSites] = useState<Site[] | []>([]);
  const [animalMovementOpen, setAnimalMovementOpen] = useState(false);
  const [animalCount, setAnimalCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [feedGroupId, setFeedGroupId] = useState("");
  const [animalMovementDataOpen, setAnimalMovementDataOpen] = useState(false);
  const [animalMovementData, setAnimalMovementData] = useState<AnimalMovement[]>([]);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedGroupSites();
  }, []);

  const handleFeedGroupSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleOpenModal = (count: number, id: string | null, startDate: string): void => {
    setAnimalCount(count);
    if (id) {
      setFeedGroupId(id);
    }
    setStartDate(startDate);
    setAnimalMovementOpen(true);
  };

  const handleOpenAnimalMovementDataModal = (animalMovements: AnimalMovement[]) => {
    setAnimalMovementData(animalMovements);
    setAnimalMovementDataOpen(true);
  };

  const navigateToFeedPlanSummary = (id: string | null): void => {
    navigate(`/feed-group-management/feed-plan-summary/${id}`);
  };

  const onSaveChanges = (row: FeedGroupManagementType, name?: keyof FeedGroupManagementType, value?: any): void => {
    const data = [...rows];

    const result = data.map((feedGroup) => {
      if (feedGroup.id === row.id) {
        if (name) {
          const barns = sites.find((site) => site.id === feedGroup.siteId)?.barns;

          if (name === "barnId") {
            feedGroup = { ...row, barnId: barns?.[0]?.id as string | undefined, bins: [] };
          }

          if (name === "bins") {
            feedGroup = { ...row, bins: [], binIds: [] };
          }

          if (name === "binIds") {
            const selectedBins = value.filter((bin: any) => feedGroup.binIds.some((id) => id === bin.id));
            feedGroup = { ...row, bins: selectedBins.map((selected: any) => selected.name) };
          }
        } else {
          feedGroup = { ...row };
        }
        onAddChanges(feedGroup, false, true);
      }
      return feedGroup;
    });
    setFeedGroups(result);
  };

  return (
    <section className='feed-group-management-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => {
          const barns = sites.find((site) => site.id === row.siteId)?.barns;
          const bins = barns?.find((barn) => row.barnId === barn.id)?.bins;

          return (
            <StyledTableRow key={`${row.id}_${index}`}>
              <StyledTableCell align='center' component='th' scope='row'>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align='center'>{row.animalType || "Empty"}</StyledTableCell>
              <StyledTableCell align='center'>{row.animalSubtype || "Empty"}</StyledTableCell>
              <EditableSelectCell
                row={row}
                name='Site'
                data={row.siteId}
                property='siteId'
                menuItems={sites}
                onSaveChanges={(row) => onSaveChanges(row, "barnId")}
                isUnsavedChanges={isUnsavedChanges}
              />
              <StyledTableCell
                align='center'
                className='feed-group-management-table__link'
                onClick={() => navigateToFeedPlanSummary(row.id)}
              >
                Feed Plan Summary
              </StyledTableCell>
              <EditableSelectCell
                row={row}
                name='Barn'
                data={row.barnId}
                property='barnId'
                menuItems={barns}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={(row) => onSaveChanges(row, "bins")}
              />
              <StyledTableCell
                onClick={() => handleOpenAnimalMovementDataModal(row.animalMovements)}
                align='center'
                className='feed-group-management-table__link'
              >
                {row.count}
              </StyledTableCell>
              <EditableMultipleSelectCell
                disabled={!barns?.[0]?.bins?.length}
                row={row}
                name='Bins'
                data={row.bins}
                property='binIds'
                menuItems={bins}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={(row) => onSaveChanges(row, "binIds", bins)}
              />
              <EditableDatePickerCell
                row={row}
                name='Start Date'
                data={new Date(row.startDate)}
                property='startDate'
                maxDate={new Date(row?.minDate || row.startDate)}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <EditableDatePickerCell
                row={row}
                name='End Date'
                data={new Date(row.endDate)}
                minDate={new Date(row?.maxDate || row.endDate)}
                property='endDate'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<EditIcon />}
                  onClick={() => handleOpenModal(row.count, row.id, row.startDate)}
                >
                  Edit
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </CustomizedTable>
      {animalMovementOpen && (
        <AnimalMovementModal
          startDate={startDate}
          open={animalMovementOpen}
          setOpen={setAnimalMovementOpen}
          id={feedGroupId}
          count={animalCount}
          fetchItems={fetchFeedGroups}
        />
      )}
      {animalMovementDataOpen && (
        <AnimalMovementDataModal
          open={animalMovementDataOpen}
          setOpen={setAnimalMovementDataOpen}
          headers={["Feed Group Name", "Date", "Count", "Type"]}
          animalMovements={animalMovementData}
        />
      )}
    </section>
  );
}
