import { Header } from "../../components/layout/header/header";
import { FeedGroupManagementTable } from "./feed-group-management-table/feed-group-management-table";
import { Footer } from "../../components/layout/footer/footer";
import { useEffect, useState } from "react";
import { useAxios } from "../../hooks";
import { Barn, FeedGroupManagementType, Site } from "../../interfaces";
import { useSnackBar } from "../../providers";
import { FeedGroupManagementFilter } from "./feed-group-management-filters/feed-group-management-filters";
import { handleAddChanged } from "../../utils/check-add";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import dayjs from "dayjs";

export function FeedGroupManagement(): JSX.Element {
  const headers = [
    "Name",
    "Animal Type",
    "Animal Subtype",
    "Site",
    "Feed Plan",
    "Barn",
    "Count",
    "Bins",
    "Start Date",
    "End Date",
    "Enter Animal Movement",
  ];

  const [feedGroups, setFeedGroups] = useState<FeedGroupManagementType[]>([]);
  const [changedRows, setChangedRows] = useState<FeedGroupManagementType[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [barns, setBarns] = useState<Barn[]>([]);
  const [selectedSites, setSelectedSites] = useState<Site[] | []>([]);
  const [selectedBarns, setSelectedBarns] = useState<Barn[] | []>([]);
  const [turnFilter, setTurnFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post, put } = useAxios();

  useEffect(() => {
    fetchSites();
    fetchFeedGroups();
  }, []);

  const refresh = (): void => {
    setIsReadyForSave(true);
    setChangedRows([]);
    fetchSites();
    fetchFeedGroups("Successfully refreshed!");
  };

  const fetchSites = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({
        url: "/client/sites",
      });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchFeedGroups = (message?: string, sites?: Site[], barns?: Barn[]): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await post<FeedGroupManagementType[]>({
        url: "/client/feed-groups/management",
        data: { sites: getSiteIds(sites), barns: getBarnIds(barns) },
      });

      setFeedGroups(data);
      setChangedRows([]);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanges = (row: FeedGroupManagementType, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as unknown as FeedGroupManagementType[]);
  };

  const getSiteIds = (sites?: Site[]): (string | null)[] => {
    if (!selectedSites?.length && !sites?.length) {
      return [];
    }

    return sites ? sites.map((site) => site?.id) : selectedSites.map((site) => site?.id);
  };

  const getBarnIds = (barns?: Barn[]): (string | null)[] => {
    if (!selectedBarns?.length && !barns?.length) {
      return [];
    }

    return barns ? barns.map((barn) => barn?.id) : selectedBarns.map((barn) => barn?.id);
  };

  const onTurnFilter = (): void => {
    setTurnFilter(!turnFilter);
    const message = `Successfully turned filter ${turnFilter ? "off" : "on"}!`;
    fetchFeedGroups(message, turnFilter ? [] : undefined, turnFilter ? [] : undefined);
  };

  const cancel = (): void => {
    setIsReadyForSave(true);
    fetchSites();
    fetchFeedGroups("Successfully cancelled!");
  };

  const save = (): void => {
    const filteredRows = changedRows.map(({ id, startDate, endDate, siteId, barnId, binIds }) => ({
      id,
      startDate: dayjs(startDate).format("MM/DD/YYYY"),
      endDate: dayjs(endDate).format("MM/DD/YYYY"),
      siteId,
      barnId,
      binIds,
    }));

    const postData = async (): Promise<void> => {
      await put<FeedGroupManagementType[]>({
        url: "client/feed-groups/management/update",
        data: { data: filteredRows },
      });

      setIsReadyForSave(true);
      fetchFeedGroups("Successfully updated");
      setChangedRows([]);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  return (
    <section className='feed-group-management'>
      <Header />

      <FeedGroupManagementFilter
        sites={sites}
        barns={barns}
        setBarns={setBarns}
        turnFilter={turnFilter}
        onTurnFilter={onTurnFilter}
        selectedSites={selectedSites}
        setSelectedSites={setSelectedSites}
        selectedBarns={selectedBarns}
        setSelectedBarns={setSelectedBarns}
        fetchFeedGroups={fetchFeedGroups}
      />

      <FeedGroupManagementTable
        headers={headers}
        rows={feedGroups}
        loading={loading}
        fetchFeedGroups={fetchFeedGroups}
        setFeedGroups={setFeedGroups}
        onAddChanges={onAddChanges}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={() => {
          console.log();
        }}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Sites'
        cancelButtonLabel='Discard changes'
        refreshButtonLabel='Refresh Feed Group Management'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </section>
  );
}
