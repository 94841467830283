import { MovingOutlined, ViewKanbanOutlined, LocalShippingOutlined, CalendarTodayOutlined } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
export interface CardManagement {
  id: number;
  title: string;
  cards: ManagementDashboardCard[];
}

interface ManagementDashboardCard {
  id: number;
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & { muiName: string };
  items: ManagementDashboardCardItems[];
}

export interface ManagementDashboardCardItems {
  label: string;
  value: number;
  color: string;
  isPercentage: boolean;
  action?: () => void;
  tooltip?: string;
}

export const cardManagement: CardManagement[] = [
  {
    id: 0,
    title: "Growth Perspective",
    cards: [
      {
        id: 0,
        Icon: MovingOutlined,
        items: [
          {
            label: "Days until outage",
            value: 0,
            color: "success",
            isPercentage: false,
          },
          {
            label: "Days of feed",
            value: 0,
            color: "warning",
            isPercentage: false,
          },
        ],
      },
      {
        id: 1,
        Icon: ViewKanbanOutlined,
        items: [
          {
            label: "Stage Feeding Accuracy",
            value: 0,
            color: "success",
            isPercentage: true,
          },
          {
            label: "Shipping Date PVA",
            value: 0,
            color: "danger",
            isPercentage: true,
          },
          {
            label: "Target Weight PVA",
            value: 0,
            color: "success",
            isPercentage: true,
          },
        ],
      },
    ],
  },
  {
    id: 1,
    title: "Fleet Efficiency",
    cards: [
      {
        id: 0,
        Icon: LocalShippingOutlined,
        items: [
          {
            label: "Average Load Size",
            value: 0,
            color: "success",
            isPercentage: false,
          },
        ],
      },
      {
        id: 1,
        Icon: ViewKanbanOutlined,
        items: [
          {
            label: "% Single Site Loads",
            value: 0,
            color: "success",
            isPercentage: true,
          },
          {
            label: "% Full Loads",
            value: 0,
            color: "success",
            isPercentage: true,
          },
          {
            label: "% Single Barn Loads",
            value: 0,
            color: "success",
            isPercentage: true,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Planning",
    cards: [
      {
        id: 0,
        Icon: CalendarTodayOutlined,
        items: [
          {
            label: "Forecast Length (days)",
            value: 0,
            color: "success",
            isPercentage: false,
          },
        ],
      },
      {
        id: 1,
        Icon: ViewKanbanOutlined,
        items: [
          {
            label: "Planned Order %",
            value: 0,
            color: "success",
            isPercentage: true,
          },
          {
            label: "Order Accuracy %",
            value: 0,
            color: "success",
            isPercentage: true,
          },
        ],
      },
    ],
  },
];
