import { Header } from "../../components/layout/header/header";
import { Footer } from "../../components/layout/footer/footer";
import { useCallback, useContext, useEffect, useState } from "react";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { CompanyUnit, FeedOrder, FeedOrderFilter, FeedOrderPagination } from "../../interfaces";
import { FeedOrdersTable } from "./feed-orders-table/feed-orders-table";
import { DownloadOutlined } from "@mui/icons-material";
import { Tooltip, IconButton, Button } from "@mui/material";
import { downloadFile } from "../../utils/download-file";
import { handleAddChanged } from "../../utils/check-add";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import "./feed-orders.scss";
import { UnitContext } from "../../contexts/unit-context";

export const DEFAULT_LIMIT = 7;

export function FeedOrders(): JSX.Element {
  const [feedOrders, setFeedOrders] = useState<FeedOrder[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [changedRows, setChangedRows] = useState<FeedOrder[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [feedOrdersFilter, setFeedOrdersFilter] = useState<FeedOrderFilter>({
    feedGroup: null,
    feedType: null,
    site: null,
    mill: null,
    barn: null,
    orderTime: null,
    scheduleTime: null,
    amount: null,
    loadNumber: null,
    status: null,
  });

  const { openSnackBarMessage } = useSnackBar();
  const { post, delete: deleteRequest } = useAxios();

  const { unitOfMeasurement } = useContext(UnitContext);

  const isStage = window.location.href.includes("devfeedplannr.mode40.com");

  useEffect(() => {
    fetchFeedOrders();
  }, [feedOrdersFilter]);

  const refresh = (): void => {
    fetchFeedOrders("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedOrders("Successfully cancelled!");
  };

  const fetchFeedOrders = useCallback(
    (message?: string, limit = DEFAULT_LIMIT, offset = DEFAULT_LIMIT * page - DEFAULT_LIMIT): void => {
      setLoading(true);
      const fetchData = async (): Promise<void> => {
        const data = await post<FeedOrderPagination>({
          url: `/client/feed-orders?limit=${limit}&offset=${offset}`,
          data: feedOrdersFilter,
        });

        setFeedOrders(data.data);
        setCount(data.count);
        setChangedRows([]);
        setIsReadyForSave(true);
        setLoading(false);
        if (message) {
          openSnackBarMessage(message, "success");
        }
      };

      fetchData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setLoading(false);
      });
    },
    [feedOrdersFilter],
  );

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const onAddChanged = (row: FeedOrder, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setChangedRows(data as any[]);
  };

  const exportFeedOrders = (): void => {
    const exportData = async (): Promise<void> => {
      const data = await post<BlobPart>({
        url: "/client/feed-orders/export",
        data: null,
      });
      downloadFile(data, "feed-orders.csv");
      openSnackBarMessage("Successfully exported data!", "success");
    };

    exportData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error.message}!`, "error"));
  };

  const deleteAll = (): void => {
    setLoading(true);
    const deleteOrders = async (): Promise<void> => {
      await deleteRequest({ url: "/client/feed-orders/all" });

      openSnackBarMessage("Successfully deleted all feed orders!", "success");
      setFeedOrders([]);
      setCount(0);
      setPage(1);
      setIsReadyForSave(true);
      setChangedRows([]);
    };

    deleteOrders()
      .catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"))
      .finally(() => setLoading(false));
  };

  const save = (): void => {
    const data = changedRows.map((item) => ({ id: item.id, status: item.status }));
    const postData = async (): Promise<void> => {
      await post<FeedOrder[]>({ url: "/client/feed-loads/feed-orders/update", data: { data } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchFeedOrders("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const confirmFeedOrders = (): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      await post<FeedOrder[]>({ url: "/client/feed-orders/confirm" });
      openSnackBarMessage("Successfully confirmed!", "success");
      fetchFeedOrders();
      setLoading(false);
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const cancelFeedOrders = (): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      await post<FeedOrder[]>({ url: "/client/feed-orders/cancel" });
      openSnackBarMessage("Successfully cancelled!", "success");
      fetchFeedOrders();
      setLoading(false);
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const headers = [
    "Feed Group",
    "Feed Type",
    "Site",
    "Mill",
    "Barn",
    "Order Time",
    "Scheduled Time",
    `Amount ${unitOfMeasurement === CompanyUnit.Imperial ? "(T)" : "(MT)"}`,
    "Load #",
    "Status",
  ];

  return (
    <section className='feed-orders-management'>
      <Header>
        <Tooltip title='Export Data'>
          <IconButton onClick={exportFeedOrders}>
            <DownloadOutlined fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Header>

      <div className='feed-orders-management__actions'>
        <Button variant='contained' color='success' endIcon={<CheckIcon />} onClick={() => confirmFeedOrders()}>
          Confirm
        </Button>
        <Button variant='contained' color='error' endIcon={<CancelIcon />} onClick={() => cancelFeedOrders()}>
          Cancel
        </Button>
        {(process.env.NODE_ENV === "development" || isStage) && (
          <Button
            variant='contained'
            color='error'
            startIcon={<CancelIcon />}
            sx={{ marginLeft: "auto" }}
            onClick={() => deleteAll()}
          >
            Remove All
          </Button>
        )}
      </div>

      <FeedOrdersTable
        headers={headers}
        rows={feedOrders}
        count={count}
        page={page}
        setPage={setPage}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
        onAddChanged={onAddChanged}
        setFeedOrders={setFeedOrders}
        fetchFeedOrders={fetchFeedOrders}
        filters={feedOrdersFilter}
        setFeedOrdersFilter={setFeedOrdersFilter}
      />

      <Footer
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        refreshButtonLabel='Refresh Feed Orders'
        cancelButtonLabel='Discard changes'
        saveButtonLabel='Save Feed Orders'
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </section>
  );
}
