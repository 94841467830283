import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { useDoubleClick, useOnClickOutside } from "../../../hooks";

import { Input, Tooltip } from "@mui/material";
import { StyledTableCell } from "../table";
import "./editable-input-cell.scss";

interface EditableTableCellProps<T> {
  row: T;
  data: string | number;
  name: string;
  property: string;
  min?: number;
  max?: number;
  allowsNull?: boolean;
  errorText?: string;
  isUnsavedChanges?: boolean;
  onSaveChanges: (row: T) => void;
  children?: ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function EditableTableCell({
  row,
  data,
  name,
  property,
  min,
  max,
  errorText,
  allowsNull,
  isUnsavedChanges,
  onSaveChanges,
  children,
  onFocus,
  onBlur,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
EditableTableCellProps<any>): JSX.Element {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState<string | number | null>(data);
  const [type, setType] = useState(typeof data);
  const [isModified, setIsModified] = useState(row?.isAdded);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    setValue(data);
    setType(typeof data);
    if (!row?.isAdded) {
      if (isModified && isUnsavedChanges) {
        setIsModified(false);
      }
    }
  }, [data, isUnsavedChanges]);

  const hybridClick = useDoubleClick(
    () => handleDoubleRowClick(),
    () => handleRowClick(),
  );

  const handleDoubleRowClick = (): void => {
    setIsEditable(!isEditable);
  };

  const handleRowClick = (): void => {
    console.log("click");
  };

  const onHandleClickOutside = (): void => {
    if (isDisabled()) {
      row[property] = value;
      onSaveChanges(row);
      setIsEditable(false);
    }
  };

  useOnClickOutside(ref, onHandleClickOutside);

  const onHandleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = (e.target as HTMLInputElement)?.value;
    if (e.key === "Enter" && isDisabled()) {
      setIsEditable(!isEditable);
      if (value === "" && allowsNull) {
        row[property] = null;
      } else {
        row[property] = value;
      }
      onSaveChanges(row);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (e.target.value === "" && allowsNull) {
      setValue(null);
    } else {
      setValue(e.target.value);
    }
    setIsModified(true);
  };

  const isDisabled = (): boolean => {
    if (!allowsNull) {
      return value !== null && value !== undefined && value !== "";
    }

    return true;
  };

  const isValid = (): boolean => {
    if (min !== undefined && max !== undefined && value !== undefined && value !== null) {
      return value < min || value > max;
    }

    if (min !== undefined && value !== undefined && value !== null && value < min) {
      setValue(min);
      return true;
    }

    return false;
  };

  return (
    <StyledTableCell align='center' className={`${isModified && "modified"} editable-field`} onClick={hybridClick}>
      {isEditable ? (
        <Input
          value={value}
          type={type}
          name={name}
          onChange={(e) => onChange(e)}
          onKeyDown={(e) => onHandleKeyDown(e)}
          ref={ref}
          error={!isDisabled() || isValid()}
          onBlur={(event) => {
            onChange(event);
            onHandleClickOutside();
            setIsEditable(false);
            onBlur && onBlur();
          }}
        />
      ) : isValid() ? (
        <Tooltip title={errorText || ""}>
          <div>{value}</div>
        </Tooltip>
      ) : (
        <button
          onFocus={() => {
            setIsEditable(true);
            onFocus && onFocus();
          }}
          className='editable-field__input-group'
        >
          <span>{value}</span>
          {children}
        </button>
      )}
    </StyledTableCell>
  );
}
