import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { ChangeEvent, useEffect, useState } from "react";
import { Driver } from "../../../interfaces";
import { generatedId } from "../../../utils/generator-ids";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";
import { DriversTable } from "./drivers-table/drivers-table";
import ImportDriversInstruction from "./import-drivers-instructions-modal/import-drivers-instructions-modal";

export function SetupDrivers(): JSX.Element {
  const headers = ["First Name", "Last Name", "Remove"];

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [changedRows, setChangedRows] = useState<Driver[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importDriversOpen, setImportDriversOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [driversFile, setDriversFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchDrivers();
  }, []);

  const refresh = (): void => {
    fetchDrivers("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchDrivers("Successfully cancelled!");
  };

  const fetchDrivers = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Driver[]>({ url: "/client/drivers" });

      setDrivers(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateDrivers = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setDriversFile(formData);
        await post({
          url: "/client/drivers/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadDrivers(formData);
          setImportDriversOpen(false);
        })
        .catch((error) => {
          setImportDriversOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadDrivers = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<Driver[]>({
        url: "/client/drivers/csv",
        data: formData || driversFile,
      });
      importDrivers(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: Driver, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setChangedRows(data as any[]);
  };

  const add = (): void => {
    const data = [...drivers];
    const driversData = [...changedRows];

    const driver: Driver = {
      id: generatedId(36),
      firstName: "Name",
      lastName: "Name",
      toDelete: false,
      isAdded: true,
    };

    driver.subId = driver.id;
    data.push(driver);
    driversData.push(driver);

    setIsReadyForSave(false);
    setDrivers(data);
    setChangedRows(driversData);
  };

  const importDrivers = (importedDrivers: Driver[]): void => {
    let data = [...drivers];
    let driversData = [...changedRows];
    importedDrivers = importedDrivers.map((driver) => {
      driver.id = generatedId(36);
      driver.subId = driver.id;
      driver.isAdded = true;
      driver.toDelete = false;
      return driver;
    });

    data = data.concat(importedDrivers);
    driversData = driversData.concat(importedDrivers);

    setIsReadyForSave(false);
    setDrivers(data);
    setChangedRows(driversData);
  };

  const save = (): void => {
    getFinalDrivers();

    const postData = async (): Promise<void> => {
      await post<Driver[]>({ url: "/client/drivers/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchDrivers("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((driver) => {
      if (driver.isAdded && !driver?.id) {
        if (driver.subId) {
          driver.id = driver.subId;
        }
      }

      return driver;
    });

    setChangedRows(data);
  };

  const getFinalDrivers = (): void => {
    let data = [...changedRows];
    data = data.map((driver) => {
      if (driver.isAdded) {
        driver.id = null;
      }
      return driver;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportDriversModal = (): void => {
    setImportDriversOpen(true);
  };

  const handleSetupDriversComplete = (): void => {
    handleSetupComplete("DRIVERS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import Setup Drivers'>
          <IconButton onClick={openImportDriversModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleSetupDriversComplete}>
          Setup Complete
        </Button>
      </Header>

      <DriversTable
        headers={headers}
        rows={drivers}
        setDrivers={setDrivers}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save System Drivers'
        addButtonLabel='Add System Drivers'
        refreshButtonLabel='Refresh System Drivers'
        cancelButtonLabel='Discard changes'
      />

      <ImportDriversInstruction
        open={importDriversOpen}
        setOpen={setImportDriversOpen}
        uploadDrivers={validateDrivers}
      />

      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadDrivers()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
