import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import "./setup-growth-adjustments-table.scss";
import { GrowthAdjustment, GrowthAdjustmentCondition, Payload } from "../../../../interfaces";
import { Dispatch, SetStateAction } from "react";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { EditableDatePickerCell } from "../../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import { EditableMultipleSelectCell } from "../../../../components/table/editable-multiple-select/editable-multiple-select";
import { useNavigate } from "react-router-dom";

interface SetupGrowthAdjustmentsTableProps {
  headers: string[];
  rows: GrowthAdjustment[];
  loading: boolean;
  setGrowthAdjustments: Dispatch<SetStateAction<GrowthAdjustment[]>>;
  onAddChanged: (row: GrowthAdjustment, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
  changedRows: GrowthAdjustment[];
  active: string | null;
  setActive: Dispatch<SetStateAction<string | null>>;
}

export function SetupGrowthAdjustmentsTable({
  headers,
  rows,
  loading,
  setGrowthAdjustments,
  onAddChanged,
  isUnsavedChanges,
  changedRows,
  active,
  setActive,
}: SetupGrowthAdjustmentsTableProps): JSX.Element {
  const navigate = useNavigate();

  const onSaveChanges = (row: GrowthAdjustment): void => {
    const data = [...rows];
    data.map((growthAdjustment) => {
      if (growthAdjustment.id === row.id) {
        growthAdjustment = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setGrowthAdjustments(data);
  };

  const removeGrowthAdjustment = (row: GrowthAdjustment): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((growthAdjustment) => growthAdjustment.id !== copyRow?.id);
      setGrowthAdjustments(data);
      onAddChanged(copyRow, true);
    }
  };

  const getConditions = (conditions: GrowthAdjustmentCondition[]): string[] => {
    return conditions?.map((condition) => condition?.type);
  };

  const getMenuConditionItems = (conditions: GrowthAdjustmentCondition[]): Payload[] => {
    return conditions?.map((condition) => ({ name: condition?.type as string, id: condition?.id as string }));
  };

  const navigateToGrowthAdjustmentConditions = (id: string | null): void => {
    navigate(`/system-setup/setup-growth-adjustments-conditions/${id}`);
  };
  return (
    <section className='setup-growth-adjustments-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => {
          const isModified = changedRows.some((changed) => row.id === changed.id);

          return (
            <StyledTableRow key={`${row.id}_${index}`}>
              <EditableTableCell
                row={row}
                data={row.adjustmentsReason}
                property='adjustmentsReason'
                name='Adjustment Reason'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(row.id)}
                onBlur={() => setActive(null)}
              />
              <EditableTableCell
                row={row}
                data={row.adjustmentsFactor}
                property='adjustmentsFactor'
                name='Adjustment Factor'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(row.id)}
                onBlur={() => setActive(null)}
              />
              <EditableMultipleSelectCell
                row={row}
                name='Conditions'
                data={getConditions(row.conditions)}
                property='conditions'
                isUnsavedChanges={isUnsavedChanges}
                menuItems={getMenuConditionItems(row.conditions)}
                onFocus={() => setActive(row.id)}
                onBlur={() => setActive(null)}
              />
              <EditableDatePickerCell
                row={row}
                name='Start Date'
                data={new Date(row.startDate)}
                property='startDate'
                maxDate={new Date(row.endDate)}
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(row.id)}
                onBlur={() => setActive(null)}
              />
              <EditableDatePickerCell
                row={row}
                name='End Date'
                minDate={new Date(row.startDate)}
                data={new Date(row.endDate)}
                property='endDate'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(row.id)}
                onBlur={() => setActive(null)}
              />
              {isModified || active === row.id ? (
                <StyledTableCell />
              ) : (
                <StyledTableCell align='center'>
                  <Button
                    color='primary'
                    variant='contained'
                    startIcon={<EditIcon />}
                    onClick={() => navigateToGrowthAdjustmentConditions(row.id)}
                  >
                    Edit
                  </Button>
                </StyledTableCell>
              )}
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='error'
                  startIcon={<CloseOutlined />}
                  onClick={() => removeGrowthAdjustment(row)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </CustomizedTable>
    </section>
  );
}
