import { AlertColor } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";

export const handleSetupComplete = (
  step:
    | "UNITS_OF_MEASUREMENT"
    | "FEED_TYPES"
    | "FEED_PLANS"
    | "ANIMALS"
    | "GROWTH_CURVES"
    | "GROWTH_ADJUSTMENTS"
    | "SITES"
    | "BARNS"
    | "FEED_GROUPS"
    | "BINS"
    | "SITE_COMBINATIONS"
    | "MILLS"
    | "DRIVERS"
    | "TRUCKS",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: <T>(params: AxiosRequestConfig<any>) => Promise<T>,
  openSnackBarMessage: (message: string, state: AlertColor | undefined) => void,
  navigate: NavigateFunction,
): void => {
  const postData = async (): Promise<void> => {
    await post<void>({ url: "/client/companies/setup/complete", data: { setupStep: step, status: true } });
    openSnackBarMessage("Successfully completed!", "success");
    navigate("/system-setup/welcome");
  };

  postData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
};
