import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./feed-plans-table.scss";
import { FeedPlan } from "../../../../interfaces";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";

interface FeedPlansTableProps {
  headers: string[];
  feedPlans: FeedPlan[];
  loading: boolean;
  isUnsavedChanges: boolean;
  setFeedPlans: Dispatch<SetStateAction<FeedPlan[]>>;
  onAddChanged: (row: FeedPlan, isDeleted?: boolean, isModified?: boolean) => void;
  changedRows: FeedPlan[];
  active: string | null;
  setActive: Dispatch<SetStateAction<string | null>>;
}

export function FeedPlansTable({
  headers,
  feedPlans,
  loading,
  isUnsavedChanges,
  setFeedPlans,
  onAddChanged,
  changedRows,
  active,
  setActive,
}: FeedPlansTableProps): JSX.Element {
  const navigate = useNavigate();

  const onSaveChanges = (row: FeedPlan): void => {
    const data = [...feedPlans];
    data.map((feedPlan) => {
      if (feedPlan.id === row.id) {
        feedPlan = row;
        onAddChanged(row, false, true);
      }
      return row;
    });
    setFeedPlans(data);
  };

  const removeFeedPlan = (row: FeedPlan): void => {
    let data = [...feedPlans];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((feedPlan) => feedPlan.id !== copyRow?.id);
      setFeedPlans(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToFeedPlanSequence = (id: string | null): void => {
    navigate(`/system-setup/feed-plans-sequence/${id}`);
  };
  return (
    <section className='feed-plans-table'>
      <CustomizedTable
        headers={headers}
        isEmpty={!feedPlans?.length}
        loading={loading}
        isUnsavedChanges={!isUnsavedChanges}
      >
        {feedPlans.map((feedPlan) => {
          const isModified = changedRows.some((changed) => feedPlan.id === changed.id);

          return (
            <StyledTableRow key={feedPlan.id}>
              <EditableTableCell
                row={feedPlan}
                data={feedPlan.name}
                property='name'
                name='Name'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(feedPlan.id)}
                onBlur={() => setActive(null)}
              />
              <EditableTableCell
                row={feedPlan}
                data={feedPlan.description}
                property='description'
                name='Description'
                isUnsavedChanges={isUnsavedChanges}
                onSaveChanges={onSaveChanges}
                onFocus={() => setActive(feedPlan.id)}
                onBlur={() => setActive(null)}
              />
              {isModified || active ? (
                <StyledTableCell />
              ) : (
                <StyledTableCell align='center'>
                  <Button
                    color='primary'
                    variant='contained'
                    startIcon={<EditIcon />}
                    onClick={() => navigateToFeedPlanSequence(feedPlan.id)}
                  >
                    Edit
                  </Button>
                </StyledTableCell>
              )}
              <StyledTableCell align='center'>
                <Button
                  variant='contained'
                  color='error'
                  startIcon={<CloseOutlined />}
                  onClick={() => removeFeedPlan(feedPlan)}
                >
                  Remove
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </CustomizedTable>
    </section>
  );
}
