import { Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  tooltip: string | undefined;
}

export function withTooltip<P extends object>(Component: React.ComponentType<P>) {
  return function ComponentWithTooltip(props: P & Props) {
    const { tooltip } = props;

    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <Component {...props} />
        </Tooltip>
      );
    } else {
      return <Component {...props} />;
    }
  };
}
