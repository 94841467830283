import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { SetupFeedGroupsTable } from "./setup-feed-groups-table/setup-feed-groups-table";
import { ChangeEvent, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { FeedGroup } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import ImportFeedGroupInstruction from "./import-feed-group-instructions-modal/import-feed-group-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";
import dayjs from "dayjs";

export function SetupFeedGroups(): JSX.Element {
  const headers = [
    "Name",
    "Feed Plan",
    "Site",
    "Barn",
    "Animal Type",
    "Animal Subtype",
    "Count",
    "Start Date",
    "Bins",
    "Growth Curve",
    "Starting Weight",
    "Starting Age",
    "Avg Weight Variance",
    "Mortality Rate",
    "End Date",
    "Enter Animal Movement",
    "Remove",
  ];

  const [feedGroups, setFeedGroups] = useState<FeedGroup[]>([]);
  const [changedRows, setChangedRows] = useState<FeedGroup[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importFeedGroupOpen, setImportFeedGroupOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [feedGroupsFile, setFeedGroupsFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchFeedGroups();
  }, []);

  const refresh = (): void => {
    fetchFeedGroups("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedGroups("Successfully cancelled!");
  };

  const fetchFeedGroups = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroup[]>({ url: "/client/feed-groups" });

      setFeedGroups(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateFeedGroups = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setFeedGroupsFile(formData);
        await post({
          url: "/client/feed-groups/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadFeedGroups(formData);
          setImportFeedGroupOpen(false);
        })
        .catch((error) => {
          setImportFeedGroupOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadFeedGroups = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<FeedGroup[]>({
        url: "/client/feed-groups/csv",
        data: formData || feedGroupsFile,
      });
      importFeedGroups(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: FeedGroup, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as FeedGroup[]);
  };

  const add = (): void => {
    const data = [...feedGroups];
    const feedGroupsData = [...changedRows];

    const feedGroup: FeedGroup = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      binIds: [],
      count: 0,
      startDate: new Date(),
      endDate: new Date(),
      feedPlanId: feedGroups[0]?.feedPlanId,
      growthCurveId: feedGroups[0]?.growthCurveId,
      bins: [],
      barnId: feedGroups[0]?.barnId,
      barn: feedGroups[0]?.barn,
      siteId: feedGroups[0]?.siteId,
      site: feedGroups[0]?.site,
      feedPlan: feedGroups[0]?.feedPlan,
      growthCurve: feedGroups[0]?.growthCurve,
      animalTypeId: feedGroups[0]?.animalTypeId,
      animalSubtypeId: feedGroups[0]?.animalSubtypeId,
      animalType: feedGroups[0]?.animalType,
      animalSubtype: feedGroups[0]?.animalSubtype,
      startingWeight: 0,
      startingAge: 0,
      avgWeightVariance: 0,
      mortalityRate: 0,
      toDelete: false,
      isAdded: true,
    };

    feedGroup.subId = feedGroup.id;
    data.push(feedGroup);
    feedGroupsData.push(feedGroup);

    setIsReadyForSave(false);
    setFeedGroups(data);
    setChangedRows(feedGroupsData);
  };

  const importFeedGroups = (importedFeedGroups: FeedGroup[]): void => {
    let data = [...feedGroups];
    let feedGroupsData = [...changedRows];
    importedFeedGroups = importedFeedGroups.map((feedGroup) => {
      feedGroup.id = generatedId(36);
      feedGroup.subId = feedGroup.id;
      feedGroup.isAdded = true;
      feedGroup.toDelete = false;
      return feedGroup;
    });

    data = data.concat(importedFeedGroups);
    feedGroupsData = feedGroupsData.concat(importedFeedGroups);

    setIsReadyForSave(false);
    setFeedGroups(data);
    setChangedRows(feedGroupsData);
  };

  const save = (): void => {
    getFinalFeedGroups();

    const postData = async (): Promise<void> => {
      await post<FeedGroup[]>({ url: "/client/feed-groups/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchFeedGroups("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((feedGroup) => {
      if (feedGroup.isAdded && !feedGroup?.id) {
        feedGroup.id = feedGroup.subId;
      }

      return feedGroup;
    });

    setChangedRows(data);
  };

  const getFinalFeedGroups = (): void => {
    let data = [...changedRows];
    data = data.map((feedPlan) => {
      if (feedPlan.isAdded) {
        feedPlan.id = null;
      }
      feedPlan.startDate = dayjs(feedPlan.startDate).format("MM/DD/YYYY");
      feedPlan.endDate = dayjs(feedPlan.endDate).format("MM/DD/YYYY");
      feedPlan.count = typeof feedPlan.count === "string" ? parseInt(feedPlan.count) : feedPlan.count;
      return feedPlan;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportFeedGroupModal = (): void => {
    setImportFeedGroupOpen(true);
  };

  const handleFeedGroupSetupComplete = (): void => {
    handleSetupComplete("FEED_GROUPS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='setup-feed-groups'>
      <Header>
        <Tooltip title='Import Setup Feed Groups'>
          <IconButton onClick={openImportFeedGroupModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleFeedGroupSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupFeedGroupsTable
        headers={headers}
        rows={feedGroups}
        setFeedGroups={setFeedGroups}
        onAddChanged={onAddChanged}
        loading={loading}
        fetchFeedGroups={fetchFeedGroups}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Feed Groups'
        addButtonLabel='Add Setup Feed Groups'
        refreshButtonLabel='Refresh Setup Feed Groups'
        cancelButtonLabel='Discard changes'
      />

      <ImportFeedGroupInstruction
        open={importFeedGroupOpen}
        setOpen={setImportFeedGroupOpen}
        uploadFeedGroups={validateFeedGroups}
      />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadFeedGroups()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
