/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Dispatch, SetStateAction, ReactElement, ReactNode } from "react";

export interface WithChildren {
  children?: ReactNode;
}

export interface RouteItem {
  label: string;
  link: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon?: ReactElement;
  description?: string;
  subItems?: RouteItem[];
}

export interface SidebarMenuItem {
  label: string;
  link: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & { muiName: string };
  expandable: boolean;
  expandableItems?: {
    label: string;
    link: string;
    tooltip?: string;
  }[];
}

export interface SidebarMenuItemIcon {
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & { muiName: string };
  link: string;
}

export interface SideBarItem {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & { muiName: string };
  link: string;
  collapsed: boolean;
  expandable: boolean;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  expandableItems: { label: string; link: string }[] | undefined;
}

export interface Driver {
  id: string | null;
  subId?: string | null;
  firstName: string;
  lastName: string;
  toDelete?: boolean;
  isAdded?: boolean;
}

export interface Truck {
  id: string | null;
  subId?: string | null;
  name: string;
  description: string;
  capacity: number;
  toDelete?: boolean;
  isAdded?: boolean;
}

export interface FeedGroupManagementType {
  id: string | null;
  subId: string | null;
  name: string;
  animalType: string;
  animalSubtype: string;
  animalMovements: AnimalMovement[];
  siteId: string;
  siteName: string;
  barnId?: string;
  barnName: string;
  count: number;
  binIds: string[];
  bins: string[];
  isAdded?: boolean;
  startDate: string;
  endDate: string;
  minDate?: string;
  maxDate?: string;
}

export interface AnimalMovement {
  id: string;
  feedGroupName: string;
  type: AnimalMovementType;
  count: number;
  dateTime: string;
}

export enum AnimalMovementType {
  ADJUSTMENT = "ADJUSTMENT",
  TRANSFER = "TRANSFER",
  DEATH = "DEATH",
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Site {
  id: string | null;
  subId: string | null;
  name: string;
  loadSize: number;
  location: Location;
  barns: Barn[];
  millId: string;
  mill: Mill;
  toDelete?: boolean;
  isAdded?: boolean;
}

export interface Barn {
  id: string | null;
  subId: string | null;
  name: string;
  siteId: string;
  site: Site;
  toDelete?: boolean;
  isAdded?: boolean;
  bins: Bin[];
}

export interface AnimalType {
  id: string;
  name: string;
  subtypes: AnimalSubtype[];
}

export interface SiteCombination {
  id: string | null;
  subId: string | null;
  name: string;
  siteIds: string[];
  sites: Site[];
  toDelete?: boolean;
  isAdded?: boolean;
}

export interface AnimalSubtype {
  id: string;
  animalTypeId: string;
  animalType: AnimalType;
  subtype: string;
  defaultAvgWeightVariance: number;
  defaultGrowthCurve?: string;
  defaultGrowthCurveId: string;
  defaultMortalityRate: number;
  defaultStartingAge: number;
  defaultStartingWeight: number;
}

export interface AnimalDataType {
  animals: AnimalSubtype[];
  animalType: AnimalType;
}

export interface FeedGroup {
  id: string | null;
  subId: string | null;
  name: string;
  binIds: string[];
  count: number;
  startDate: string | Date;
  endDate: string | Date;
  feedPlanId: string;
  growthCurveId: string;
  bins: Bin[];
  barnId: string;
  barn: Barn;
  siteId: string;
  site: Site;
  feedPlan: FeedPlan;
  animalTypeId: string;
  animalSubtypeId: string;
  animalType: AnimalType;
  animalSubtype: AnimalSubtype;
  growthCurve: GrowthCurve;
  startingWeight: number;
  startingAge: number;
  avgWeightVariance: number;
  mortalityRate: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface BinManagementType {
  id: string;
  name: string;
  siteId: string;
  siteName: string;
  site: Site;
  barnId: string;
  barnName: string;
  volume: number;
  inventory: number;
  date: string | Date;
  binInventories?: BinInventory[];
}

export interface BinInventory {
  binName: string;
  date: string;
  inventory: number;
}

export interface Bin {
  id: string;
  name: string;
  site: Site;
  barnId: string;
  barn: Barn;
  volume: number;
  inventory: number;
  feedTypeId: string;
  feedType: FeedType;
  date: string;
  feedGroup?: any;
}

export interface FeedPlan {
  id: string | null;
  subId: string | null;
  name: string;
  toDelete: boolean;
  isAdded: boolean;
  description: string;
}
export interface Company {
  id: string;
  name: string;
  friendlyName: string;
  auth0Id: string;
}
export interface FeedType {
  id: string | null;
  subId: string | null;
  name: string;
  density: number;
  toDelete?: boolean;
  isAdded?: boolean;
  densityMeasurement?: {
    value: number;
  };
}

export interface Payload {
  id: string;
  name: string;
}

export interface GrowthAdjustment {
  id: string | null;
  subId: string | null;
  adjustmentsReason: string;
  adjustmentsFactor: number;
  conditions: GrowthAdjustmentCondition[];
  startDate: string | Date;
  endDate: string | Date;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface GrowthAdjustmentCondition {
  id: string | null;
  type: keyof typeof ConditionType;
  criteria: {
    id: string;
    name: string;
  }[];
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface GrowthCurve {
  id: string | null;
  subId: string | null;
  name: string;
  description: string;
  isAdded?: boolean;
  toDelete?: boolean;
  [key: string]: any;
}

export interface Log {
  id: string;
  timestamp: Date;
  logClass: string;
  issueType: string;
  severity: string;
  description: string;
}

export interface LogDescription {
  issueType: string;
  count: number;
}
export interface GrowthCurveSequence {
  id: string | null;
  subId: string | null;
  day: number;
  growthKgsPerHead: number;
  consumptionKgsPerHead: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface FeedOrderPagination {
  data: FeedOrder[];
  count: number;
}

export interface FeedOrderFilter {
  feedGroup: null | string;
  feedType: null | string;
  site: null | string;
  mill: null | string;
  barn: null | string;
  orderTime: null | string;
  scheduleTime: null | string;
  amount: null | number;
  loadNumber: null | number;
  status: null | string;
}

export interface FeedOrder {
  id: string;
  feedGroupId: string;
  feedGroup: string;
  feedTypeId: string;
  feedType: string;
  barnId: string;
  barn: string;
  site: string;
  orderTime: Date | string;
  mill: string;
  millId: string;
  scheduledDate: string;
  amount: number;
  loadId: string;
  loadNumber: number;
  status: string;
}
export interface WeeklySnapshotData {
  dates: string[];
  orders: number[];
  targets: number[];
  percentOfCapacity: number[];
  daysOfFeed: number[];
}

export interface WeeklySnapshot {
  name: string;
  data: number[];
}

export interface FeedPlanSequenceType {
  id: string | null;
  subId: string | null;
  number: number;
  feedTypeId: string;
  feedType: FeedType;
  kgsPerHead: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface Order {
  id: string | null;
  subId: string | null;
  feedOrder: string;
  feedOrderId: string;
  feedLoadId: string;
  feedTypeId: string;
  feedType: string;
  status: string;
  orderNumber: string;
  barn: Barn;
  site: Site;
  binId: string;
  feedTypeName: string;
  feedGroupName: string;
  feedGroupId: string;
  bins: string[];
  binName: string;
  orderTime: Date;
  scheduledDate: string;
  loadNumber: number;
  amount: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface FeedLoad {
  id: string | null;
  subId: string | null;
  millId: string;
  mill: Mill;
  number: number;
  orderTime: Date;
  scheduledDate: string;
  orders: Order[];
  status: string;
  barnId: string;
  barn: Barn;
  site: Site;
  maxAmount: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface NextDay {
  date: string;
  value: number;
  orders: Order[];
  areBinsOverfilled: boolean;
  isFeedGroupFinished: boolean;
  summaryOrder: number;
}

export interface FeedGroupForecastData {
  feedGroupId: string;
  feedGroup: string;
  site: string;
  barn: string;
  bins: string[];
  capacity: number;
  animals: number;
  nextDays: NextDay[];
}

export interface FeedForecast {
  weeklySnapshotData: WeeklySnapshotData;
  feedGroupForecastData: FeedGroupForecastData[];
}

export interface ProductionSequenceProps {
  id: string | null;
  subId: string | null;
  number: number;
  feedGroupId: string;
  feedGroup: string;
  feedTypeId: string;
  feedType: string;
  barnId: string;
  barn: string;
  siteId: string;
  site: string;
  orderTime: Date;
  scheduledDate: string;
  amount: number;
  loadId: string;
  loadNumber: number;
  status: string;
  millId: string;
  mill: string;
  daysRemainingInventory: number;
  sequencingValue: number;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface User {
  id: string | null;
  subId: string | null;
  email: string;
  name: string;
  isAdmin: boolean;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface Mill {
  id: string | null;
  subId: string | null;
  name: string;
  mondayTarget: number;
  tuesdayTarget: number;
  wednesdayTarget: number;
  thursdayTarget: number;
  fridayTarget: number;
  saturdayTarget: number;
  sundayTarget: number;
  canBeDeleted: boolean;
  algorithmStrategy: string;
  algorithmName: string;
  isAdded?: boolean;
  toDelete?: boolean;
}

export interface FeedOrderAlgorithm {
  algorithmStrategy: string;
  algorithmName: string;
}

export interface AnimalForecast {
  id: string;
  expectedDeathsForDate: number;
  expectedDeathsToDate: number;
  actualDeathsUpToDate: number;
  date: Date;
  weight: number;
  forecastedDeaths: number;
  count: number;
  isWeightEstimated: boolean;
  age: number;
}

export interface LoadManagement {
  id: string;
  totalAmount: number;
  expectedDeliveryTime: Date | string;
  status: Status;
  siteIds: string[];
  sites: Site[];
  driverId: string;
  number: number;
  driver: Driver;
  truckId: string;
  truck: Truck;
}

export enum Status {
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  ORDERED = "ORDERED",
  PRODUCED = "PRODUCED",
  SCHEDULED = "SCHEDULED",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
}

export enum CompanyUnit {
  Imperial = "IMPERIAL",
  Metric = "METRIC",
}

export interface Unit {
  id: string;
  unit: CompanyUnit;
}

export enum ConditionType {
  FEED_GROUP = "feed-groups",
  MILL = "mills",
  SITE = "sites",
  ANIMAL_TYPE = "animal-types",
  ANIMAL_SUBTYPE = "animals",
}

export type ConditionTypeResponse = {
  "feed-groups": FeedGroup[];
  mills: Mill[];
  sites: Site[];
  "animal-types": AnimalType[];
  animals: AnimalSubtype[];
};
