import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import { Footer } from "../../../../components/layout/footer/footer";
import { Header } from "../../../../components/layout/header/header";
import { useAxios } from "../../../../hooks";
import { CompanyUnit, GrowthCurve, GrowthCurveSequence } from "../../../../interfaces";
import { useSnackBar } from "../../../../providers";
import { generatedId } from "../../../../utils/generator-ids";
import { useNavigate, useParams } from "react-router-dom";
import { GrowthCurveSequenceTable } from "./growth-curves-sequence-table/growth-curves-sequence-table";
import ImportGrowthCurveSequenceInstruction from "./import-growth-curves-sequence-instructions-modal/import-growth-curves-sequence-instructions-modal";
import { handleSetupComplete } from "../../../../utils/setup-complete";
import { handleAddChanged } from "../../../../utils/check-add";
import { UnitContext } from "../../../../contexts/unit-context";

export function GrowthCurvesSequence(): JSX.Element {
  const { id } = useParams();
  const [growthCurvesSequence, setGrowthCurvesSequence] = useState<GrowthCurveSequence[]>([]);
  const [changedRows, setChangedRows] = useState<GrowthCurveSequence[]>([]);
  const [growthCurveName, setGrowthCurveName] = useState<string>("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importGrowthCurvesSequenceOpen, setImportGrowthCurvesSequenceOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  const { unitOfMeasurement } = useContext(UnitContext);

  useEffect(() => {
    fetchGrowthCurve();
    fetchGrowthCurvesSequence();
  }, []);

  const refresh = (): void => {
    fetchGrowthCurvesSequence("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchGrowthCurvesSequence("Successfully cancelled!");
  };

  const fetchGrowthCurve = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthCurve>({
        url: `/client/growth-curves/${id}`,
      });

      setGrowthCurveName(data?.name);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchGrowthCurvesSequence = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthCurveSequence[]>({
        url: `/client/growth-curves/${id}/items`,
      });

      setGrowthCurvesSequence(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: GrowthCurveSequence, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as GrowthCurveSequence[]);
  };

  const add = (): void => {
    const data = [...growthCurvesSequence];
    const growthCurvesSequenceData = [...changedRows];
    const nextDay = growthCurvesSequence[growthCurvesSequence?.length - 1]?.day + 1;

    const growthCurvesSequenceItem: GrowthCurveSequence = {
      id: generatedId(36),
      subId: "",
      day: isNaN(nextDay) ? 1 : nextDay,
      growthKgsPerHead: 0,
      consumptionKgsPerHead: 0,
      toDelete: false,
      isAdded: true,
    };

    growthCurvesSequenceItem.subId = growthCurvesSequenceItem.id;
    data.push(growthCurvesSequenceItem);
    growthCurvesSequenceData.push(growthCurvesSequenceItem);

    setIsReadyForSave(false);
    setGrowthCurvesSequence(data);
    setChangedRows(growthCurvesSequenceData);
  };

  const importGrowthCurvesSequence = (importedGrowthCurvesSequence: GrowthCurveSequence[]): void => {
    let data = [...growthCurvesSequence];
    let growthCurvesSequenceData = [...changedRows];
    importedGrowthCurvesSequence = importedGrowthCurvesSequence.map((growthCurvesSequenceItem) => {
      growthCurvesSequenceItem.id = generatedId(36);
      growthCurvesSequenceItem.subId = growthCurvesSequenceItem.id;
      growthCurvesSequenceItem.isAdded = true;
      growthCurvesSequenceItem.toDelete = false;
      return growthCurvesSequenceItem;
    });

    data = data.concat(importedGrowthCurvesSequence);
    growthCurvesSequenceData = growthCurvesSequenceData.concat(importedGrowthCurvesSequence);

    setIsReadyForSave(false);
    setGrowthCurvesSequence(data);
    setChangedRows(growthCurvesSequenceData);
  };

  const save = (): void => {
    getFinalGrowthCurvesSequence();

    const postData = async (): Promise<void> => {
      await post<GrowthCurveSequence[]>({
        url: `/client/growth-curves/${id}/items/update`,
        data: { data: changedRows },
      });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchGrowthCurvesSequence("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((growthCurvesSequenceItem) => {
      if (growthCurvesSequenceItem.isAdded && !growthCurvesSequenceItem?.id) {
        growthCurvesSequenceItem.id = growthCurvesSequenceItem.subId;
      }

      return growthCurvesSequenceItem;
    });

    setChangedRows(data);
  };

  const getFinalGrowthCurvesSequence = (): void => {
    let data = [...changedRows];
    data = data.map((growthCurvesSequenceItem) => {
      if (growthCurvesSequenceItem.isAdded) {
        growthCurvesSequenceItem.id = null;
      }
      return growthCurvesSequenceItem;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportGrowthCurvesSequenceModal = (): void => {
    setImportGrowthCurvesSequenceOpen(true);
  };

  const handleGrowthCurvesSetupComplete = (): void => {
    handleSetupComplete("GROWTH_CURVES", post, openSnackBarMessage, navigate);
  };

  const headers = [
    "Day",
    `Growth ${unitOfMeasurement === CompanyUnit.Imperial ? "lbs per Head per Day" : "Kg Per Head Per Day"}`,
    `Consumption ${unitOfMeasurement === CompanyUnit.Imperial ? "lbs per Head per Day" : "Kg Per Head Per Day"}`,
    "Remove",
  ];

  return (
    <div className='feed-plan-sequence'>
      <Header>
        <Tooltip title='Import Growth Curve Sequence'>
          <IconButton onClick={openImportGrowthCurvesSequenceModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleGrowthCurvesSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <GrowthCurveSequenceTable
        headers={headers}
        growthCurvesSequence={growthCurvesSequence}
        growthCurveName={growthCurveName}
        setGrowthCurvesSequence={setGrowthCurvesSequence}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Growth Curve Sequence'
        addButtonLabel='Add Growth Curve Sequence'
        refreshButtonLabel='Refresh Growth Curve Sequence'
        cancelButtonLabel='Discard changes'
      />

      <ImportGrowthCurveSequenceInstruction
        open={importGrowthCurvesSequenceOpen}
        setOpen={setImportGrowthCurvesSequenceOpen}
        importGrowthCurvesSequence={importGrowthCurvesSequence}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
