import { useLocation } from "react-router-dom";
import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import "./bin-inventory-history-table.scss";
import { BinInventory } from "../../../interfaces";

interface BinInventoryHistoryTableProps {
  headers: string[];
}

export function BinInventoryHistoryTable({ headers }: BinInventoryHistoryTableProps) {
  const { state } = useLocation();
  const { binInventories } = state as { binInventories: BinInventory[] };

  return (
    <section className='bin-inventory-history-table'>
      <CustomizedTable headers={headers} isEmpty={!binInventories?.length}>
        {binInventories.map((row, index) => (
          <StyledTableRow key={`${row.binName}_${index}`}>
            <StyledTableCell focusable component='th' scope='row' align='center'>
              {row.binName}
            </StyledTableCell>
            <StyledTableCell focusable align='center'>
              {row.date}
            </StyledTableCell>
            <StyledTableCell focusable align='center'>
              {row.inventory}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
