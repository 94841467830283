import React, { Dispatch, SetStateAction } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../table/table";
import { AnimalMovement } from "../../interfaces";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headers: string[];
  animalMovements: AnimalMovement[];
}

export function AnimalMovementDataModal({ open, setOpen, headers, animalMovements }: Props) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={false}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
      maxWidth={"lg"}
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Animal Movement History
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers} isEmpty={!animalMovements?.length}>
          {animalMovements.map((row, index) => (
            <StyledTableRow key={row.id + "_" + index}>
              <StyledTableCell align='center'>{row.feedGroupName}</StyledTableCell>
              <StyledTableCell align='center'>
                {dayjs(row.dateTime.replace("T", " ")).utc(true).format("MM/DD/YYYY hh:mm A")}
              </StyledTableCell>
              <StyledTableCell align='center'>{row.count}</StyledTableCell>
              <StyledTableCell align='center'>{row.type}</StyledTableCell>
            </StyledTableRow>
          ))}
        </CustomizedTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' color='error'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
