import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { SetupSitesTable } from "./setup-sites-table/setup-sites-table";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { CompanyUnit, Site } from "../../../interfaces";
import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { generatedId } from "../../../utils/generator-ids";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import ImportSiteInstruction from "./import-site-instructions-modal/import-site-instructions-modal";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";
import { UnitContext } from "../../../contexts/unit-context";

export function SetupSites(): JSX.Element {
  const [sites, setSites] = useState<Site[]>([]);
  const [changedRows, setChangedRows] = useState<Site[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importSiteOpen, setImportSiteOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sitesFile, setSitesFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  const { unitOfMeasurement } = useContext(UnitContext);

  useEffect(() => {
    fetchSites();
  }, []);

  const refresh = (): void => {
    fetchSites("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchSites("Successfully cancelled!");
  };

  const fetchSites = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateSites = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setSitesFile(formData);
        await post({
          url: "/client/sites/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadSites(formData);
          setImportSiteOpen(false);
        })
        .catch((error) => {
          setImportSiteOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadSites = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<Site[]>({
        url: "/client/sites/csv",
        data: formData || sitesFile,
      });
      importSites(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: Site, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as Site[]);
  };

  const add = (): void => {
    const data = [...sites];
    const siteData = [...changedRows];

    const site: Site = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      loadSize: data[0]?.loadSize,
      location: data[0]?.location,
      millId: data[0]?.millId,
      mill: data[0]?.mill,
      barns: [],
      toDelete: false,
      isAdded: true,
    };

    site.subId = site.id;
    data.push(site);
    siteData.push(site);

    setIsReadyForSave(false);
    setSites(data);
    setChangedRows(siteData);
  };

  const importSites = (importedSites: Site[]): void => {
    let data = [...sites];
    let siteData = [...changedRows];
    importedSites = importedSites.map((site) => {
      site.id = generatedId(36);
      site.subId = site.id;
      site.isAdded = true;
      site.toDelete = false;
      return site;
    });

    data = data.concat(importedSites);
    siteData = siteData.concat(importedSites);

    setIsReadyForSave(false);
    setSites(data);
    setChangedRows(siteData);
  };

  const getFinalSetupSites = (): void => {
    let data = [...changedRows];

    data = data.map((site) => {
      if (site.isAdded) {
        site.id = null;
      }

      return site;
    });

    setChangedRows(data);
  };

  const save = (): void => {
    getFinalSetupSites();

    const postData = async (): Promise<void> => {
      await post<Site[]>({ url: "/client/sites/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchSites("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((site) => {
      if (site.isAdded && !site?.id) {
        site.id = site.subId;
      }

      return site;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportSitesModal = (): void => {
    setImportSiteOpen(true);
  };

  const handleSitesSetupComplete = (): void => {
    handleSetupComplete("SITES", post, openSnackBarMessage, navigate);
  };

  const headers = [
    "Name",
    `Load Size ${unitOfMeasurement === CompanyUnit.Imperial ? "(T)" : "(MT)"}`,
    "Mill",
    "Remove",
  ];

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import Setup Sites'>
          <IconButton onClick={openImportSitesModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleSitesSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupSitesTable
        headers={headers}
        rows={sites}
        setSites={setSites}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Sites'
        addButtonLabel='Add Setup Sites'
        refreshButtonLabel='Refresh Setup Sites'
        cancelButtonLabel='Discard changes'
      />

      <ImportSiteInstruction open={importSiteOpen} setOpen={setImportSiteOpen} uploadSites={validateSites} />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadSites()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
