import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../../hooks";
import { AnimalForecast, FeedGroup } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { FeedGroupDetailsChart } from "./feed-group-detail-chart/feed-group-detail-chart";
import { FeedGroupDetailTable } from "./feed-group-detail-table/feed-group-detail-table";
import "./feed-group-detail-content.scss";
import { Footer } from "../../../components/layout/footer/footer";

const headers = ["Name", "Animal Type", "Animal Subtype", "Site", "Barn ", "Bins", "Count", "Feed Plan"];

export function FeedGroupDetailsContent() {
  const { id } = useParams();

  const [feedGroup, setFeedGroup] = useState<FeedGroup>();
  const [animalForecast, setAnimalForecast] = useState<AnimalForecast[]>();
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    if (id) {
      fetchFeedGroup();
      fetchAnimalForecast();
    }
  }, []);

  const refresh = (): void => {
    fetchFeedGroup("Successfully refreshed!");
    fetchAnimalForecast("Successfully refreshed!");
  };

  const fetchFeedGroup = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroup>({
        url: `/client/feed-groups/${id}`,
      });

      setFeedGroup(data);
      setLoading(false);

      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchAnimalForecast = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await post<AnimalForecast[]>({
        url: `/client/feed-groups/${id}/animal-forecast/calculate`,
      });

      setAnimalForecast(data);
      setLoading(false);

      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  if (!feedGroup) {
    return <></>;
  }

  return (
    <div className='feed-group-feed-detail-content'>
      <FeedGroupDetailTable headers={headers} row={feedGroup} loading={loading} />

      <div className='feed-group-feed-detail-content__charts-container'>
        <FeedGroupDetailsChart
          chartData={animalForecast?.map((forecast) => {
            return { date: dayjs(forecast.date).format("DD/MM/YYYY"), weight: forecast.weight };
          })}
          type='weight'
          loading={loading}
        />
        <FeedGroupDetailsChart
          chartData={animalForecast?.map((forecast) => {
            return { date: dayjs(forecast.date).format("DD/MM/YYYY"), count: forecast.count };
          })}
          type='count'
          loading={loading}
        />
      </div>

      <Footer onRefresh={refresh} refreshButtonLabel='Recalculate Forecast for Feed Group' />
    </div>
  );
}
