import { useState } from "react";
import { Link } from "react-router-dom";
import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { FeedGroupForecastData, Order } from "../../../../interfaces";
import "./feed-forecast-feed-group-table.scss";
import FeedOrdersModal from "./feed-orders-modal/feed-orders-modal";

interface FeedForecastFeedGroupTableProps {
  headers: string[];
  rows: FeedGroupForecastData[];
  loading: boolean;
}

export function FeedForecastFeedGroupTable({ headers, rows, loading }: FeedForecastFeedGroupTableProps): JSX.Element {
  const [feedOrdersOpen, setFeedOrdersOpen] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);

  const openFeedOrdersModal = (orders: Order[]): void => {
    setOrders(orders);
    setFeedOrdersOpen(true);
  };

  return (
    <section className='feed-forecast-feed-group-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading}>
        {rows.map((row) => (
          <StyledTableRow key={row.feedGroup}>
            <StyledTableCell component='th' scope='row' align='center'>
              <Link
                to={`/feed-forecast/feed-group-detail/${row.feedGroupId}`}
                className={"feed-forecast-feed-group-table__details"}
              >
                {row.feedGroup}
              </Link>
            </StyledTableCell>
            <StyledTableCell align='center'>{row.site}</StyledTableCell>
            <StyledTableCell align='center'>{row.barn}</StyledTableCell>
            <StyledTableCell align='center'>{row.bins.join(", ")}</StyledTableCell>
            <StyledTableCell align='center'>{row.capacity}</StyledTableCell>
            <StyledTableCell align='center'>{row.animals}</StyledTableCell>
            {row?.nextDays?.map((dayItem) => {
              if (dayItem.isFeedGroupFinished) {
                return <StyledTableCell align='center' className={"feed-forecast-feed-group-table__row--empty"} />;
              }

              return (
                <StyledTableCell
                  align='center'
                  key={dayItem.date}
                  className={`feed-forecast-feed-group-table__row ${
                    dayItem?.value >= 2 && "feed-forecast-feed-group-table__row--success"
                  } ${
                    ((dayItem?.value < 2 && dayItem?.value >= 0) || dayItem.areBinsOverfilled) &&
                    "feed-forecast-feed-group-table__row--warning"
                  } ${dayItem?.value < 0 && "feed-forecast-feed-group-table__row--danger"}`}
                >
                  {dayItem?.value}{" "}
                  {dayItem?.summaryOrder > 0 && (
                    <span
                      className='feed-forecast-feed-group-table__summary-order'
                      onClick={() => openFeedOrdersModal(dayItem.orders)}
                    >
                      ({dayItem?.summaryOrder})
                    </span>
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        ))}
      </CustomizedTable>
      {feedOrdersOpen && (
        <FeedOrdersModal open={feedOrdersOpen} setOpen={setFeedOrdersOpen} orders={orders} setOrders={setOrders} />
      )}
    </section>
  );
}
