/* eslint-disable @typescript-eslint/no-explicit-any */
import "./animal-movement-dialog.scss";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { EditableTableCell } from "../table/editable-input-cell/editable-input-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../table/table";
import { FeedGroupManagementType } from "../../interfaces";
import { EditableSelectCell } from "../table/editable-select-cell/editable-select-cell";
import { EditableDateTimePickerCell } from "../table/editable-datetimepicker-cell/editable-datetimepicker-cell";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface AnimalMovementProps {
  open: boolean;
  id: string;
  count: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchItems: () => void;
  startDate: string | Date;
}

interface InitialState {
  count: number;
  feedGroupId: string | null;
  type: string;
  dateTime: string | Date;
}

const AnimalMovementModal = ({ open, count, id, setOpen, fetchItems, startDate }: AnimalMovementProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [headers, setHeaders] = useState(["Count", "Type", "Date"]);
  const types = ["DEATH", "ADJUSTMENT", "TRANSFER"];
  const [feedGroups, setFeedGroups] = useState<FeedGroupManagementType[]>([]);
  const [data, setData] = useState<InitialState>({
    count,
    feedGroupId: "",
    type: "DEATH",
    dateTime: new Date(),
  });

  const { openSnackBarMessage } = useSnackBar();
  const { post, get } = useAxios();

  useEffect(() => {
    handleFeedGroup();
  }, [id]);

  const handleFeedGroup = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroupManagementType[]>({ url: "/client/feed-groups" });

      setFeedGroups(data);
      if (data[0]?.id) {
        setData((prevState) => ({ ...prevState, feedGroupId: data[0]?.id }));
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleUpdate = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedGroupManagementType[]>({
        url: `/client/feed-groups/${id}/movements`,
        data: {
          ...data,
          dateTime: dayjs(data.dateTime).utc().format("MM/DD/YYYY hh:mm:ss"),
        },
      });

      fetchItems();
      openSnackBarMessage("Successfully updated!", "success");
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onChange = (e: SelectChangeEvent<string>): void => {
    setData((prevState) => ({ ...prevState, type: e.target.value }));
    if (e.target.value === "TRANSFER") {
      setHeaders(["Count", "Type", "Date", "Transfer To"]);
    } else {
      setHeaders(["Count", "Type", "Date"]);
    }
  };

  const onSaveChanges = (value: any): void => {
    setData((prevState) => ({ ...prevState, count: value.count }));
  };

  const onSaveFeedGroupChanges = (value: any): void => {
    setData((prevState) => ({ ...prevState, feedGroupId: value.feedGroupId }));
  };

  const onChangeDateTime = (date: { dateTime: Date }): void => {
    setData((prevState) => ({ ...prevState, dateTime: date.dateTime }));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Update Animal Movement
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers}>
          <StyledTableRow>
            <EditableTableCell
              row={data}
              name='Count'
              min={data.type === "ADJUSTMENT" ? undefined : 0}
              data={data.count}
              property='count'
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center' className='editable-field'>
              <FormControl variant='standard'>
                <Select value={data.type} label='Type' onChange={(e) => onChange(e)}>
                  {types &&
                    types.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </StyledTableCell>
            <EditableDateTimePickerCell
              row={data}
              data={new Date(data.dateTime)}
              minDate={new Date(startDate)}
              maxDate={data.type === "DEATH" ? new Date() : undefined}
              name='Date'
              property='dateTime'
              onSaveChanges={onChangeDateTime}
            />
            {data.type === "TRANSFER" && (
              <EditableSelectCell
                row={data}
                name='Site'
                data={data.feedGroupId}
                property='feedGroupId'
                menuItems={feedGroups}
                onSaveChanges={onSaveFeedGroupChanges}
              />
            )}
          </StyledTableRow>
        </CustomizedTable>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleUpdate} className='responsive-dialog__button-update'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AnimalMovementModal;
