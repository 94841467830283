import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import "./trucks-table.scss";
import { Truck } from "../../../../interfaces";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { Dispatch, SetStateAction } from "react";

interface TrucksTableProps {
  headers: string[];
  rows: Truck[] | [];
  loading: boolean;
  setTrucks: Dispatch<SetStateAction<Truck[]>>;
  onAddChanged: (row: Truck, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
}

export function TrucksTable({
  headers,
  rows,
  loading,
  setTrucks,
  onAddChanged,
  isUnsavedChanges,
}: TrucksTableProps): JSX.Element {
  const onSaveChanges = (row: Truck): void => {
    const data = [...rows];
    data.map((truck) => {
      if (truck.id === row.id) {
        truck = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setTrucks(data);
  };

  const removeTruck = (row: Truck): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((truck) => truck.id !== copyRow?.id);
      setTrucks(data);
      onAddChanged(copyRow, true);
    }
  };
  return (
    <section className='trucks-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.description}
              property='description'
              name='Description'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.capacity}
              property='capacity'
              name='Capacity'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button variant='contained' color='error' startIcon={<CloseOutlined />} onClick={() => removeTruck(row)}>
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
