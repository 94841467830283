import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { EditableTableCell } from "../../../components/table/editable-input-cell/editable-input-cell";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../../../components/table/table";
import { useAxios } from "../../../hooks";
import { FeedLoad, Order } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import { handleAddChanged } from "../../../utils/check-add";

interface DeliveryModalProp {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  orders: Order[];
  loading: boolean;
  setFeedLoadOrders: Dispatch<SetStateAction<Order[]>>;
  feedLoad: FeedLoad | undefined;
  refresh: (message?: string) => void;
}

export function DeliveryModal({
  open,
  setOpen,
  setFeedLoadOrders,
  orders,
  loading,
  feedLoad,
  refresh,
}: DeliveryModalProp) {
  const headers = ["Feed Group", "Feed Type", "Site", "Barn", "Bin", "Amount", "Max Load Size"];
  const [changedRows, setChangedRows] = useState<Order[]>([]);
  const [isReadyForSave, setIsReadyForSave] = useState(true);

  const { post, patch } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const save = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedLoad>({ url: `/client/feed-orders/${feedLoad?.id}/update`, data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
    };

    postData()
      .then(() => {
        handleStatusChanged();
      })
      .catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: Order): void => {
    const data = [...orders];
    data?.map((order) => {
      if (order.id === row.id) {
        order = { ...row };
        const data = handleAddChanged(row, [...changedRows], false, true);
        setIsReadyForSave(false);
        setChangedRows(data as Order[]);
      }
      return row;
    });
    setFeedLoadOrders(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStatusChanged = async () => {
    try {
      await patch({ url: `/client/feed/loads/${feedLoad?.id}/status/delivered` });
      refresh("Status was successfully updated");
      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={false}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogContent>
        <CustomizedTable headers={headers} isEmpty={!orders?.length} loading={loading}>
          {orders.map((row, index) => (
            <StyledTableRow key={row.id + "_" + index}>
              <StyledTableCell align='center'>{row?.feedGroupName}</StyledTableCell>
              <StyledTableCell align='center'>{row?.feedTypeName}</StyledTableCell>
              <StyledTableCell align='center'>{row?.site?.name}</StyledTableCell>
              <StyledTableCell align='center'>{row?.barn?.name}</StyledTableCell>
              <EditableSelectCell
                row={row}
                data={row?.binId}
                property='binId'
                menuItems={row?.barn?.bins}
                isUnsavedChanges={isReadyForSave}
                onSaveChanges={onSaveChanges}
                name='Bin'
              />
              <EditableTableCell
                row={row}
                data={row?.amount}
                property='amount'
                name='Amount'
                isUnsavedChanges={isReadyForSave}
                onSaveChanges={onSaveChanges}
              />
              <StyledTableCell align='center'>{feedLoad?.maxAmount}</StyledTableCell>
            </StyledTableRow>
          ))}
        </CustomizedTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' color='error'>
          Close
        </Button>
        <Button autoFocus onClick={save} variant='outlined'>
          {isReadyForSave ? "Confirm" : "Confirm Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
