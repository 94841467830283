import React, { Dispatch, SetStateAction } from "react";
import CustomizedTable, { StyledTableRow } from "../../../../components/table/table";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { CompanyUnit, Unit } from "../../../../interfaces";
import "./setup-unit-table.scss";

interface SetupUnitTableProps {
  headers: string[];
  row: Unit;
  loading: boolean;
  setCompany: Dispatch<SetStateAction<Unit>>;
  onAddChanged: (row: Unit, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean | undefined;
}

export function SetupUnitTable({
  headers,
  row,
  loading,
  onAddChanged,
  setCompany,
  isUnsavedChanges,
}: SetupUnitTableProps) {
  const onSaveChanges = (row: Unit): void => {
    const data = { ...row };
    onAddChanged(row, false, true);
    setCompany(data);
  };

  return (
    <section className='setup-unit-table'>
      <CustomizedTable headers={headers} loading={loading} isUnsavedChanges={isUnsavedChanges}>
        <StyledTableRow key={`${row.id}`}>
          <EditableSelectCell
            row={row}
            name='Unit'
            data={row?.unit}
            property='unit'
            menuItems={Object.entries(CompanyUnit).map((unit) => {
              return {
                id: unit[1],
                name: unit[0],
              };
            })}
            onSaveChanges={onSaveChanges}
          />
        </StyledTableRow>
      </CustomizedTable>
    </section>
  );
}
