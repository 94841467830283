/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { FeedTypesTable } from "./feed-types-table/feed-types-table";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { CompanyUnit, FeedType } from "../../../interfaces";
import { useSnackBar } from "../../../providers";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { generatedId } from "../../../utils/generator-ids";
import ImportFeedTypeInstruction from "./import-feed-type-instructions-modal/import-feed-type-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";
import { UnitContext } from "../../../contexts/unit-context";

export function SystemFeedTypes(): JSX.Element {
  const [feedTypes, setFeedTypes] = useState<FeedType[]>([]);
  const [changedRows, setChangedRows] = useState<FeedType[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importFeedTypesOpen, setImportFeedTypesOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [feedTypesFile, setFeedTypesFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });

  const { unitOfMeasurement } = useContext(UnitContext);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchFeedTypes();
  }, []);

  const refresh = (): void => {
    fetchFeedTypes("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchFeedTypes("Successfully cancelled!");
  };

  const fetchFeedTypes = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedType[]>({ url: "/client/feed-types" });

      setFeedTypes(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const onAddChanged = (row: FeedType, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as FeedType[]);
  };

  const validateFeedTypes = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setFeedTypesFile(formData);
        await post({
          url: "/client/feed-types/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadFeedTypes(formData);
          setImportFeedTypesOpen(false);
        })
        .catch((error) => {
          setImportFeedTypesOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const add = (): void => {
    const data = [...feedTypes];
    const feedTypesData = [...changedRows];

    const feedType: FeedType = {
      id: generatedId(36),
      subId: "",
      name: "Type",
      density: 0,
      toDelete: false,
      isAdded: true,
    };

    feedType.subId = feedType.id;
    data.push(feedType);
    feedTypesData.push(feedType);

    setIsReadyForSave(false);
    setFeedTypes(data);
    setChangedRows(feedTypesData);
  };

  const importFeedTypes = (importedFeedTypes: FeedType[]): void => {
    let data = [...feedTypes];
    let feedTypesData = [...changedRows];
    importedFeedTypes = importedFeedTypes.map((feedType) => {
      feedType.id = generatedId(36);
      feedType.subId = feedType.id;
      feedType.isAdded = true;
      feedType.toDelete = false;
      return feedType;
    });

    data = data.concat(importedFeedTypes);
    feedTypesData = feedTypesData.concat(importedFeedTypes);

    setIsReadyForSave(false);
    setFeedTypes(data);
    setChangedRows(feedTypesData);
  };

  const getFinalFeedTypes = (): void => {
    let data = [...changedRows];

    data = data.map((feedType) => {
      if (feedType.isAdded) {
        feedType.id = feedType.subId;
      }

      return feedType;
    });

    setChangedRows(data);
  };

  const uploadFeedTypes = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<FeedType[]>({
        url: "/client/feed-types/csv",
        data: formData || feedTypesFile,
      });
      importFeedTypes(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const save = (): void => {
    getFinalFeedTypes();

    const postData = async (): Promise<void> => {
      await post<FeedType[]>({ url: "/client/feed-types/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchFeedTypes("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((feedType) => {
      if (feedType.isAdded && !feedType?.id) {
        feedType.id = generatedId(36);
      }

      return feedType;
    });

    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const openImportFeedTypesModal = (): void => {
    setImportFeedTypesOpen(true);
  };

  const handleFeedTypesSetupComplete = (): void => {
    handleSetupComplete("FEED_TYPES", post, openSnackBarMessage, navigate);
  };

  const headers = [
    "Name",
    `Density ${unitOfMeasurement === CompanyUnit.Imperial ? "(Lbs/ Cubic Foot)" : "(Kg/hL)"}`,
    "Remove",
  ];

  return (
    <div className='system-feed-types'>
      <Header>
        <Tooltip title='Import Setup Feed Types'>
          <IconButton onClick={openImportFeedTypesModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleFeedTypesSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <FeedTypesTable
        headers={headers}
        rows={feedTypes}
        setFeedTypes={setFeedTypes}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save Setup Feed Types'
        addButtonLabel='Add Setup Feed Types'
        refreshButtonLabel='Refresh Setup Feed Types'
        cancelButtonLabel='Discard changes'
      />

      <ImportFeedTypeInstruction
        open={importFeedTypesOpen}
        setOpen={setImportFeedTypesOpen}
        uploadFeedTypes={validateFeedTypes}
      />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadFeedTypes()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
