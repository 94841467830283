import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import { Filter } from "../table";
import { DateTimePicker } from "../../date-picker/date-picker";
import { useState } from "react";
import dayjs from "dayjs";

interface Props {
  filter: Filter;
  header: string;
  handleSearch: (key: string, value: string) => void;
  clearFilter: (key: string) => void;
}

export function FilterMenu({ filter, header, handleSearch, clearFilter }: Props) {
  const [searchValue, setSearchValue] = useState<string | null>(filter.value);

  let content;

  if (filter.key.includes("Time")) {
    content = (
      <DateTimePicker
        style={{ backgroundColor: "white" }}
        dateTime={searchValue}
        name={header}
        onChangeDate={(dateTime) => {
          const date = dayjs(dateTime).format("MM/DD/YYYY");
          setSearchValue(date);
        }}
      />
    );
  } else {
    content = (
      <Stack p={"8px 0px"}>
        <FormControl>
          <TextField label={header} value={searchValue} onChange={({ target }) => setSearchValue(target.value)} />
        </FormControl>
      </Stack>
    );
  }

  return (
    <Box sx={{ padding: "8px 12px" }}>
      <Typography>Filter By:</Typography>
      {content}
      {searchValue ? (
        <Stack gap={"8px"}>
          <Button variant='contained' onClick={() => handleSearch(filter.key, searchValue)}>
            Search
          </Button>
          {filter.value && (
            <Button variant='outlined' color='error' onClick={() => clearFilter(filter.key)}>
              Clear Filter
            </Button>
          )}
        </Stack>
      ) : null}
    </Box>
  );
}
