/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Driver } from "../interfaces";
import { useSnackBar } from "../providers";
import useAxios from "./use-axios.hook";

export const useGetDrivers = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]);

  const { get } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const fetchDrivers = async () => {
    try {
      const data = await get<Driver[]>({ url: "/client/drivers" });
      setDrivers(data);
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  return { drivers };
};
