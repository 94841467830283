/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { useAxios } from "../../hooks";
import { LoadManagement } from "../../interfaces";
import { useSnackBar } from "../../providers";
import { LoadManagementTable } from "./load-management-table/load-management-table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export function LoadManagementScreen(): JSX.Element {
  const headers = ["Load ID", "Total Amount", "Expected Delivery Time", "Status", "Site", "Driver", "Truck"];

  const [loadManagement, setLoadManagement] = useState<LoadManagement[]>([]);
  const [loading, setLoading] = useState(false);

  const { openSnackBarMessage } = useSnackBar();
  const { get, put } = useAxios();

  useEffect(() => {
    fetchLoadManagements();
  }, []);

  const refresh = (): void => {
    fetchLoadManagements("Successfully refreshed!");
  };

  const fetchLoadManagements = async (message?: string) => {
    setLoading(true);
    try {
      const data = await get<LoadManagement[]>({ url: "/client/feed-orders/management" });
      setLoadManagement(data);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    } catch (error: any) {
      setLoading(false);
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    }
  };

  const editFeedLoadManagement = async (
    id: string,
    driverId: string,
    truckId: string,
    expectedDeliveryTime: Date | string,
  ) => {
    setLoading(true);

    const date = dayjs(new Date(expectedDeliveryTime.toString().replace("T", " "))).format("MM/DD/YYYY hh:mm:ss");

    try {
      await put<LoadManagement[]>({
        url: `/client/feed/loads/${id}/management`,
        data: {
          driverId,
          truckId,
          expectedDeliveryTime: date,
        },
      });
      openSnackBarMessage("Successfully updated!", "success");
      fetchLoadManagements();
      setLoading(false);
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    }
  };

  return (
    <div className='production-sequence'>
      <Header />

      <LoadManagementTable
        headers={headers}
        rows={loadManagement}
        loading={loading}
        refresh={refresh}
        editFeedLoadManagement={editFeedLoadManagement}
      />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh Load Management' />
    </div>
  );
}
