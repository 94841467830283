import { ChangeEvent, ReactElement, useContext, useEffect, useState } from "react";
import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { useAxios, useDebounce } from "../../hooks";
import { CompanyUnit, Mill, ProductionSequenceProps } from "../../interfaces";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useSnackBar } from "../../providers";
import { TextField, Typography } from "@mui/material";
import { ProductionSequenceTable } from "./production-sequence-table/production-sequence-table";
import "./production-sequence.scss";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import { UnitContext } from "../../contexts/unit-context";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function ProductionSequence(): JSX.Element {
  const [productionSequence, setProductionSequence] = useState<ProductionSequenceProps[]>([]);
  const [mills, setMills] = useState<Mill[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [changedRows, setChangedRows] = useState<ProductionSequenceProps[]>([]);
  const [searchKeyWord, setSearchKeyword] = useState("");
  const debouncedSearchTerm = useDebounce(searchKeyWord, 500);

  const { openSnackBarMessage } = useSnackBar();
  const { get, patch, post } = useAxios();

  const { unitOfMeasurement } = useContext(UnitContext);

  useEffect(() => {
    fetchMills();
  }, []);

  useEffect(() => {
    if (mills[activeTab]?.id) {
      fetchProductionSequence(mills[activeTab].id || "", searchKeyWord);
    }
  }, [debouncedSearchTerm]);

  const refresh = (): void => {
    fetchMills("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchMills("Successfully cancelled!");
  };

  const fetchMills = (message?: string): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Mill[]>({ url: "/client/mills" });

      setMills(data);
      if (data?.length && data[activeTab]?.id) {
        fetchProductionSequence(data[activeTab].id || "", searchKeyWord, message);
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const fetchProductionSequence = (millId: string, searchKeyWord: string, message?: string): void => {
    setLoading(true);
    const search = encodeURIComponent(searchKeyWord);
    const fetchData = async (): Promise<void> => {
      let data = await get<ProductionSequenceProps[]>({
        url: `/client/feed-orders/search?millId=${millId}&searchKeyWord=${encodeURIComponent(search)}`,
      });
      data = data.map((item, index) => {
        item.number = index + 1;
        return item;
      });
      setProductionSequence(data);
      setChangedRows([]);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  // const a11yProps = (index: number): { id: string; "aria-controls": string } => {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TabPanel = (props: TabPanelProps): ReactElement<any, any> => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
    if (mills[newValue]) {
      fetchProductionSequence(mills[newValue].id || "", searchKeyWord);
    }
  };

  const getUniqueIds = (value: string, index: number, self: string[]): boolean => {
    return self.indexOf(value) === index;
  };

  const save = (): void => {
    const sequenceIds = productionSequence.map((row) => row.id || "");
    const updatedIds = changedRows.map((row) => row.id || "");
    let data = sequenceIds.concat(updatedIds);
    data = data.filter((value: string, index: number, self: string[]) => getUniqueIds(value, index, self));
    const millId = mills[activeTab].id;
    if (data?.length && millId) {
      const postData = async (): Promise<void> => {
        await patch<ProductionSequenceProps[]>({
          url: "/client/feed-loads/feed-orders/sequence",
          data: { data },
        });
        fetchProductionSequence(millId, searchKeyWord, "Successfully saved!");
        setIsReadyForSave(true);
        setChangedRows([]);
        setLoading(false);
      };

      postData().catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setChangedRows([]);
        setLoading(false);
      });
    }
  };

  const onChangeStatus = (data: { id: string; name: string }): void => {
    const postData = async (): Promise<void> => {
      await post<ProductionSequenceProps[]>({
        url: "/client/feed-loads/feed-orders/update",
        data: { data: [{ id: data.id, status: data.name }] },
      });

      setIsReadyForSave(true);
      setChangedRows([]);
      fetchMills("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
  };

  const onChangeSearchKeyword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchKeyword(event.target.value);
  };

  const headers = [
    "",
    "Feed Group",
    "Feed Type",
    "Site",
    "Barn",
    "Scheduled Time",
    `Amount ${unitOfMeasurement === CompanyUnit.Imperial ? "(T)" : "(MT)"}`,
    "Load #",
    "Status",
    "Days Remaining Inventory",
  ];

  return (
    <div className='production-sequence'>
      <Header />

      <Box sx={{ width: "100%" }} className='production-sequence__tabs'>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleChange} aria-label='mills tabs'>
            {mills?.map((mill) => {
              return <Tab label={mill.name} key={mill.id} />;
            })}
          </Tabs>
        </Box>
        <TextField
          label='Search...'
          value={searchKeyWord}
          onChange={(event) => onChangeSearchKeyword(event)}
          sx={{ mt: 2 }}
          variant='outlined'
        />
        {mills?.map((mill, index) => (
          <TabPanel key={mill.id} value={activeTab} index={index}>
            <ProductionSequenceTable
              headers={headers}
              rows={productionSequence}
              setProductionSequence={setProductionSequence}
              setIsReadyForSave={setIsReadyForSave}
              loading={loading}
              isUnsavedChanges={isReadyForSave}
              changedRows={changedRows}
              onChangeStatus={onChangeStatus}
              setChangedRows={setChangedRows}
              refresh={refresh}
            />
          </TabPanel>
        ))}
      </Box>

      <Footer
        onRefresh={refresh}
        onSave={save}
        refreshButtonLabel='Refresh Production Sequence'
        saveButtonLabel='Save Production Sequence'
        cancelButtonLabel='Discard changes'
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
