import { Button } from "@mui/material";
import { useEffect, useState } from "react";

import { Footer } from "../../components/layout/footer/footer";
import { Header } from "../../components/layout/header/header";
import { useAxios } from "../../hooks";
import { useSnackBar } from "../../providers";
import { SetupSteps } from "./setup-steps/setup-steps";

export interface SetupProgress {
  stepName: string;
  status: boolean;
  tooltip?: string;
}

export function SystemSetup(): JSX.Element {
  const [setupSteps, setSetupSteps] = useState<SetupProgress[]>([]);

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    fetchSetupProgress();
  }, []);

  const fetchSetupProgress = (message?: string): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<SetupProgress[]>({ url: "/client/companies/setup/progress" });

      setSetupSteps(data);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const refresh = (): void => {
    fetchSetupProgress("Successfully refreshed!");
  };

  return (
    <div className='system-setup'>
      <Header>
        <Button variant='contained' color='primary'>
          Setup Complete
        </Button>
      </Header>

      <SetupSteps steps={setupSteps} />

      <Footer onRefresh={refresh} refreshButtonLabel='Refresh System Setup' />
    </div>
  );
}
