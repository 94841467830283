import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import "./bin-management-table.scss";
import { BinManagementType, Site } from "../../../interfaces";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import { EditableDatePickerCell } from "../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import { Button } from "@mui/material";
import BinManagementUpdateModal from "../bin-management-update-modal/bin-management-update-modal";
import { Link } from "react-router-dom";

interface BinManagementTableProps {
  headers: string[];
  rows: BinManagementType[];
  loading: boolean;
  fetchBinManagement: () => void;
}

export function BinManagementTable({
  headers,
  rows,
  loading,
  fetchBinManagement,
}: BinManagementTableProps): JSX.Element {
  const [sites, setSites] = useState<Site[] | []>([]);
  const [binUpdateOpen, setBinUpdateOpen] = useState(false);
  const [activeBinId, setActiveBinId] = useState<string>("");

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedGroupSites();
  }, []);

  const handleFeedGroupSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleOpenModal = (id: string, date: Date | string): void => {
    if (date) {
      setActiveBinId(id);
      setBinUpdateOpen(true);
    }
  };
  return (
    <section className='bin-management-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <StyledTableCell focusable component='th' scope='row' align='center'>
              {row.name}
            </StyledTableCell>
            <EditableSelectCell row={row} name='Site' data={row.siteId} property='siteId' menuItems={sites} />
            <EditableSelectCell
              row={row}
              name='Barn'
              data={row.barnId}
              property='barnId'
              menuItems={row?.site?.barns}
            />
            <StyledTableCell focusable align='center'>
              {row.volume}
            </StyledTableCell>
            <StyledTableCell focusable align='center'>
              <Link
                className='bin-management-table__link'
                to={`/bin-management/bin-inventory-history/${row.id}`}
                state={{ binInventories: row.binInventories }}
              >
                {row.inventory}
              </Link>
            </StyledTableCell>
            <EditableDatePickerCell row={row} data={new Date(row.date)} name='Date' property='date' />
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<EditIcon />}
                onClick={() => handleOpenModal(row.id, row.date)}
              >
                Update
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>

      {binUpdateOpen && (
        <BinManagementUpdateModal
          open={binUpdateOpen}
          setOpen={setBinUpdateOpen}
          id={activeBinId}
          fetchBinManagement={fetchBinManagement}
        />
      )}
    </section>
  );
}
