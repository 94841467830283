import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import "./feed-orders-table.scss";
import { Barn, FeedGroup, FeedOrder, FeedOrderFilter, FeedType } from "../../../interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import { EditableDatePickerCell } from "../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import { Pagination } from "@mui/material";
import { DEFAULT_LIMIT } from "../feed-orders";
import { Link } from "react-router-dom";

interface FeedOrdersTableProps {
  headers: string[];
  rows: FeedOrder[];
  loading: boolean;
  count: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setFeedOrders: Dispatch<SetStateAction<FeedOrder[]>>;
  setFeedOrdersFilter: Dispatch<SetStateAction<FeedOrderFilter>>;
  onAddChanged: (row: FeedOrder, isDeleted?: boolean, isModified?: boolean) => void;
  fetchFeedOrders: (message: string, limit: number, offset: number) => void;
  isUnsavedChanges: boolean;
  filters: FeedOrderFilter;
}

export function FeedOrdersTable({
  headers,
  rows,
  loading,
  count,
  page,
  setPage,
  isUnsavedChanges,
  onAddChanged,
  setFeedOrders,
  fetchFeedOrders,
  filters,
  setFeedOrdersFilter,
}: FeedOrdersTableProps): JSX.Element {
  const [feedGroups, setFeedGroups] = useState<FeedGroup[] | []>([]);
  const [barns, setBarns] = useState<Barn[] | []>([]);
  const [feedTypes, setFeedTypes] = useState<FeedType[] | []>([]);
  const statuses = [
    {
      id: "PENDING",
      name: "PENDING",
    },
    {
      id: "CANCELLED",
      name: "CANCELLED",
    },
    {
      id: "ORDERED",
      name: "ORDERED",
    },
    {
      id: "PLANNED",
      name: "PLANNED",
    },
    {
      id: "PRODUCED",
      name: "PRODUCED",
    },
    {
      id: "DELIVERED",
      name: "DELIVERED",
    },
  ];

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedOrderGroups();
    handleFeedOrderBarns();
    handleFeedOrderFeedTypes();
  }, []);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
    fetchFeedOrders("Successfully fetched!", DEFAULT_LIMIT, DEFAULT_LIMIT * value - DEFAULT_LIMIT);
  };

  const onSaveChanges = (row: FeedOrder): void => {
    const data = [...rows];
    data.map((feedOrder) => {
      if (feedOrder.id === row.id) {
        feedOrder = { ...row };
        onAddChanged(row, false, true);
      }
      return row;
    });
    setFeedOrders(data);
  };

  const handleFeedOrderGroups = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedGroup[]>({ url: "/client/feed-groups" });

      setFeedGroups(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedOrderBarns = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Barn[]>({ url: "/client/barns" });

      setBarns(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedOrderFeedTypes = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedType[]>({ url: "/client/feed-types" });

      setFeedTypes(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleSearch = (key: string, value: string | null) => {
    setFeedOrdersFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleClearFilter = (key: string) => {
    setFeedOrdersFilter((prevState) => ({ ...prevState, [key]: null }));
  };

  return (
    <section className='feed-orders-table'>
      <CustomizedTable
        headers={headers}
        filters={Object.entries(filters).map(([key, value]) => {
          return { key, value };
        })}
        handleSearch={handleSearch}
        clearFilter={handleClearFilter}
        isEmpty={!rows?.length}
        loading={loading}
        isUnsavedChanges={!isUnsavedChanges}
      >
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableSelectCell
              row={row}
              name='Feed Group'
              data={row.feedGroupId}
              property='feedGroupId'
              menuItems={feedGroups}
            />
            <EditableSelectCell
              row={row}
              name='Feed Type'
              data={row.feedTypeId}
              property='feedTypeId'
              menuItems={feedTypes}
            />
            <StyledTableCell align='center'>{row?.site}</StyledTableCell>
            <StyledTableCell align='center'>{row?.mill}</StyledTableCell>
            <EditableSelectCell row={row} name='Barn' data={row.barnId} property='barnId' menuItems={barns} />
            <EditableDatePickerCell row={row} name='Order Time' data={row.orderTime} property='orderTime' />
            <StyledTableCell align='center'>{row?.scheduledDate}</StyledTableCell>
            <StyledTableCell align='center'>{row?.amount}</StyledTableCell>
            <StyledTableCell align='center'>
              <Link to={`/feed-orders/feed-load/${row.loadId}`} className='feed-orders-table__link'>
                {row?.loadNumber}
              </Link>
            </StyledTableCell>
            <EditableSelectCell
              row={row}
              name='Status'
              data={row.status}
              property='status'
              menuItems={statuses}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
          </StyledTableRow>
        ))}
      </CustomizedTable>
      <div className='feed-orders-table__pagination'>
        <Pagination
          count={Math.ceil(count / DEFAULT_LIMIT)}
          page={page}
          shape='rounded'
          color='primary'
          onChange={handleChangePage}
        />
      </div>
    </section>
  );
}
