import "./expanded-menu.scss";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import { withTooltip } from "../../../../../utils/withTooltip";

interface ExpandedMenuProps {
  expanded: boolean;
  expandableItems: { label: string; link: string; tooltip?: string }[] | undefined;
}

export const ExpandedMenu = ({ expanded, expandableItems }: ExpandedMenuProps): JSX.Element => {
  return (
    <div className='expanded-menu'>
      <Collapse in={expanded} timeout='auto' unmountOnExit className='expanded-menu__items'>
        {expandableItems &&
          expandableItems.map((item, index) => {
            const ExpandItem = withTooltip(NavLink);

            return (
              <ExpandItem
                key={index}
                to={item.link}
                className={({ isActive }) => `expanded-menu__link ${isActive && "expanded-menu__link--active"}`}
                tooltip={item.tooltip}
              >
                {item.label}
              </ExpandItem>
            );
          })}
      </Collapse>
    </div>
  );
};
