import { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { StyledTableCell } from "../table";
import "./editable-select-cell.scss";

interface EditableTableCellProps<T> {
  row: T;
  data: string | undefined | null;
  name: string;
  property?: string;
  menuItems: T[] | [] | undefined;
  onChangeValue?: (value: string, id: string) => void;
  isUnsavedChanges?: boolean;
  onSaveChanges?: (row: T) => void;
  onItemPress?: (value: string, id: string) => void;
}

export function EditableSelectCell({
  row,
  data,
  name,
  property,
  menuItems,
  onChangeValue,
  isUnsavedChanges,
  onSaveChanges,
  onItemPress,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
EditableTableCellProps<any>): JSX.Element {
  const [value, setValue] = useState<string | undefined | null>(data || "");
  const [isModified, setIsModified] = useState(row?.isAdded);

  useEffect(() => {
    setValue(data);
    if (!row?.isAdded) {
      if (isModified && isUnsavedChanges) {
        setIsModified(false);
      }
    }
  }, [data, isUnsavedChanges]);

  const onChange = (e: SelectChangeEvent<string | null>): void => {
    if (onSaveChanges && property) {
      row[property] = e.target.value;
      setValue(e.target.value);
      if (onChangeValue && e.target.value) {
        onChangeValue(e.target.value, row.id);
      }
      setIsModified(true);
      onSaveChanges(row);
    }
  };
  return (
    <StyledTableCell align='center' className={`${isModified && "modified"} editable-field`}>
      <FormControl variant='standard'>
        <Select
          disableUnderline
          value={value}
          label={name}
          onChange={(e) => onChange(e)}
          IconComponent={ExpandMoreOutlinedIcon}
        >
          {menuItems &&
            menuItems.map((item) => (
              <MenuItem
                value={item?.id}
                key={item?.id}
                onClick={() => onItemPress && onItemPress(item.name, item.id)}
                disabled={value === item.name}
              >
                {item?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </StyledTableCell>
  );
}
