/* eslint-disable @typescript-eslint/no-explicit-any */
import "./schedule-modal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateTimePicker } from "../../../components/date-picker/date-picker";
import { useAxios } from "../../../hooks";
import { Driver, LoadManagement, Truck } from "../../../interfaces";
import { useSnackBar } from "../../../providers";

interface ScheduleModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: (message?: string) => void;
  row: LoadManagement | undefined;
  drivers: Driver[];
  trucks: Truck[];
}

const ScheduleModal = ({ open, setOpen, row, refresh, drivers, trucks }: ScheduleModalProps) => {
  const [date, setDate] = useState(row?.expectedDeliveryTime);
  const [driverId, setDriverId] = useState<string | undefined>(row?.driverId);
  const [truckId, setTruckId] = useState<string | undefined>(row?.truckId);

  const { patch } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDriverId(row?.driverId);
    setTruckId(row?.truckId);
  }, [row]);

  const handleChange = () => {
    const updateStatus = async () => {
      const data = {
        expectedDeliveryTime: date,
        driverId,
        truckId,
      };
      await patch({ url: `/client/feed/loads/${row?.id}/status/scheduled`, data: data });
    };

    updateStatus()
      .then(() => {
        setOpen(false);
        refresh("Status was successfully updated");
      })
      .catch((error) => {
        openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={false}
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Change status to Schedule
      </DialogTitle>

      <DialogContent className='schedule-modal__body'>
        <DateTimePicker
          className='schedule-modal__date-picker'
          dateTime={date ? date : undefined}
          name={"Expected Delivery Time"}
          disableFuture={false}
          disablePast={false}
          onChangeDate={(date) => setDate(date?.toISOString())}
        />
        <FormControl fullWidth>
          <InputLabel>Driver</InputLabel>
          <Select value={driverId || ""} label='Driver' className='schedule-modal__select'>
            {drivers.map((driver) => (
              <MenuItem onClick={() => setDriverId(driver.id || "")} value={driver.id || ""} key={driver.id}>
                {driver.firstName + " " + driver.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Truck</InputLabel>
          <Select value={truckId || ""} label='Truck' className='schedule-modal__select'>
            {trucks.map((truck) => (
              <MenuItem onClick={() => setTruckId(truck.id || "")} value={truck.id || ""} key={truck.id}>
                {truck.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' color='error'>
          Close
        </Button>
        <Button autoFocus onClick={handleChange} variant='outlined'>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;
