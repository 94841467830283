import React, { useContext, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { Unit } from "../../../interfaces";
import { Header } from "../../../components/layout/header/header";
import { Button } from "@mui/material";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { SetupUnitTable } from "./setup-unit-table/setup-unit-table";
import { Footer } from "../../../components/layout/footer/footer";
import { UnitContext } from "../../../contexts/unit-context";

export function SetupUnit() {
  const [company, setCompany] = useState<Unit>({} as Unit);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState<boolean | undefined>(false);

  const { setUnitOfMeasurement } = useContext(UnitContext);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, put, post } = useAxios();

  const fetchCompany = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<Unit>({ url: "/client/companies" });

      setCompany(data);
      setLoading(false);
      setIsEdited(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const onAddChanged = (row: Unit, isDeleted?: boolean, isModified?: boolean) => {
    setIsEdited(isModified);
  };

  const handleSitesSetupComplete = (): void => {
    handleSetupComplete("UNITS_OF_MEASUREMENT", post, openSnackBarMessage, navigate);
  };

  const save = (): void => {
    const putData = async (): Promise<void> => {
      await put<Unit>({ url: "/client/companies", data: { unit: company.unit } });
      setUnitOfMeasurement(company.unit);
      fetchCompany("Successfully updated!");
    };

    putData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    });
  };

  const refresh = (): void => {
    fetchCompany("Successfully refreshed!");
  };

  const cancel = (): void => {
    fetchCompany("Successfully cancelled!");
  };

  return (
    <div className='system-setup'>
      <Header>
        <Button variant='contained' color='primary' onClick={handleSitesSetupComplete}>
          Setup Complete
        </Button>
      </Header>

      <SetupUnitTable
        headers={["Units of Measurement"]}
        row={company}
        loading={loading}
        setCompany={setCompany}
        onAddChanged={onAddChanged}
        isUnsavedChanges={isEdited}
      />

      <Footer
        onSave={save}
        onRefresh={refresh}
        onCancel={cancel}
        saveButtonDisabled={!isEdited}
        cancelButtonDisabled={!isEdited}
        saveButtonLabel='Save Changes'
        refreshButtonLabel='Refresh'
        cancelButtonLabel='Discard changes'
      />
    </div>
  );
}
