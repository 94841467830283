import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./growth-adjustment-conditions-table.scss";
import { Dispatch, SetStateAction } from "react";
import { ConditionType, GrowthAdjustmentCondition, Payload } from "../../../../../interfaces";
import CustomizedTable, { StyledTableRow, StyledTableCell } from "../../../../../components/table/table";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent, FormControl, Select, MenuItem } from "@mui/material";
import { EditableMultipleSelectCell } from "../../../../../components/table/editable-multiple-select/editable-multiple-select";

interface GrowthAdjustmentConditionsTableProps {
  headers: string[];
  growthAdjustmentConditions: GrowthAdjustmentCondition[];
  loading: boolean;
  setGrowthAdjustmentConditions: Dispatch<SetStateAction<GrowthAdjustmentCondition[]>>;
  onAddChanged: (row: GrowthAdjustmentCondition, isDeleted?: boolean, isModified?: boolean) => void;
  isUnsavedChanges: boolean;
  menuItems: { [k: string]: Payload[] };
}

export function GrowthAdjustmentConditionsTable({
  headers,
  growthAdjustmentConditions,
  loading,
  setGrowthAdjustmentConditions,
  onAddChanged,
  isUnsavedChanges,
  menuItems,
}: GrowthAdjustmentConditionsTableProps): JSX.Element {
  const navigate = useNavigate();

  const conditionTypes = Object.keys(ConditionType);

  const isDisabled = (type: string) => {
    return growthAdjustmentConditions.some((condition) => condition.type === type);
  };

  const onSaveChanges = (row: GrowthAdjustmentCondition): void => {
    const data = [...growthAdjustmentConditions];
    data.map((item) => {
      if (item.id === row.id) {
        item = row;
        onAddChanged(row, false, true);
      }
      return row;
    });
    setGrowthAdjustmentConditions(data);
  };

  const removeGrowthAdjustmentCondition = (row: GrowthAdjustmentCondition): void => {
    let data = [...growthAdjustmentConditions];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((item) => item.id !== copyRow?.id);
      setGrowthAdjustmentConditions(data);
      onAddChanged(copyRow, true);
    }
  };

  const navigateToGrowthAdjustments = (): void => {
    navigate("/system-setup/growth-adjustments");
  };

  const onChange = (e: SelectChangeEvent<string>, row: GrowthAdjustmentCondition): void => {
    const data = [...growthAdjustmentConditions];

    const { value } = e.target;

    data.forEach((condition) => {
      if (condition.id === row.id) {
        condition.type = value as keyof typeof ConditionType;
        onSaveChanges(condition);
      }
    });
  };

  const getCriteriaName = (criteria: { id: string; name: string }[]) => {
    return criteria.map((element) => element.name);
  };

  return (
    <section className='growth-adjustment-conditions-table'>
      <div className='growth-adjustment-conditions-table__header' onClick={navigateToGrowthAdjustments}>
        <ArrowBackIcon fontSize='medium' className='growth-adjustment-conditions-table__back-icon' />
        <p className='growth-adjustment-conditions-table__title'>Edit Adjustment Reason</p>
      </div>
      <CustomizedTable
        headers={headers}
        isEmpty={!growthAdjustmentConditions?.length}
        loading={loading}
        isUnsavedChanges={!isUnsavedChanges}
      >
        {growthAdjustmentConditions.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <StyledTableCell align='center'>
              <FormControl variant='standard'>
                <Select value={row.type} label='Condition Type' onChange={(e) => onChange(e, row)}>
                  {conditionTypes.map((type) => (
                    <MenuItem disabled={isDisabled(type)} value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledTableCell>
            <EditableMultipleSelectCell
              row={row}
              data={getCriteriaName(row.criteria)}
              name={"Conditions Configs"}
              property={"criteria"}
              valueProperty='criteria'
              menuItems={menuItems[row.type]}
              onSaveChanges={onSaveChanges}
              isUnsavedChanges={isUnsavedChanges}
            />
            <StyledTableCell align='center'>
              <Button
                variant='outlined'
                startIcon={<CloseOutlined />}
                onClick={() => removeGrowthAdjustmentCondition(row)}
              >
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
    </section>
  );
}
