import { Button, IconButton, Tooltip } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Footer } from "../../../components/layout/footer/footer";
import { Header } from "../../../components/layout/header/header";
import { FeedPlansTable } from "./feed-plans-table/feed-plans-table";
import { ChangeEvent, useEffect, useState } from "react";
import { FeedPlan } from "../../../interfaces";
import { generatedId } from "../../../utils/generator-ids";
import { useAxios } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import ImportFeedPlanInstruction from "./import-feed-plan-instructions-modal/import-feed-plan-instructions-modal";
import { handleSetupComplete } from "../../../utils/setup-complete";
import { useNavigate } from "react-router-dom";
import { handleAddChanged } from "../../../utils/check-add";
import { getFileFormData } from "../../../utils/append-file";
import ValidationModal from "../../../components/validation-modal/validation-modal";

export function SystemFeedPlans(): JSX.Element {
  const headers = ["Name", "Description", "Feed Plan Sequence", "Remove"];

  const [feedPlans, setFeedPlans] = useState<FeedPlan[]>([]);
  const [changedRows, setChangedRows] = useState<FeedPlan[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importFeedPlanOpen, setImportFeedPlanOpen] = useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [feedPlansFile, setFeedPlansFile] = useState<FormData>();
  const [validationState, setValidationState] = useState({
    error: "",
    modalState: false,
  });
  const [active, setActive] = useState<string | null>(null);

  const navigate = useNavigate();
  const { openSnackBarMessage } = useSnackBar();
  const { get, post } = useAxios();

  useEffect(() => {
    fetchFeedPlans();
  }, []);

  const refresh = (): void => {
    fetchFeedPlans("Successfully refreshed!");
    setActive(null);
  };

  const cancel = (): void => {
    fetchFeedPlans("Successfully cancelled!");
    setActive(null);
  };

  const fetchFeedPlans = (message?: string): void => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedPlan[]>({ url: "/client/feed-plans" });

      setFeedPlans(data);
      setChangedRows([]);
      setActive(null);
      setIsReadyForSave(true);
      setLoading(false);
      if (message) {
        openSnackBarMessage(message, "success");
      }
    };

    fetchData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setLoading(false);
    });
  };

  const validateFeedPlans = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const formData = getFileFormData(files);

      const validateData = async (): Promise<void> => {
        setFeedPlansFile(formData);
        await post({
          url: "/client/feed-plans/csv/validate",
          data: formData,
        });
      };

      validateData()
        .then(() => {
          uploadFeedPlans(formData);
          setImportFeedPlanOpen(false);
        })
        .catch((error) => {
          setImportFeedPlanOpen(false);
          setValidationState({
            error: `${error?.response?.data?.message || error?.message}!`,
            modalState: true,
          });
        });
    }
  };

  const uploadFeedPlans = (formData?: FormData): void => {
    const postData = async (): Promise<void> => {
      const data = await post<FeedPlan[]>({
        url: "/client/feed-plans/csv",
        data: formData || feedPlansFile,
      });
      importFeedPlans(data);

      openSnackBarMessage("Successfully imported!", "success");
      setValidationState({ error: "", modalState: false });
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setValidationState({ error: "", modalState: false });
    });
  };

  const onAddChanged = (row: FeedPlan, isDeleted?: boolean, isModified?: boolean): void => {
    const data = handleAddChanged(row, [...changedRows], isDeleted, isModified);

    setIsReadyForSave(false);
    setChangedRows(data as FeedPlan[]);
  };

  const add = (): void => {
    const data = [...feedPlans];
    const feedPlansData = [...changedRows];

    const feedPlan: FeedPlan = {
      id: generatedId(36),
      subId: "",
      name: "Name",
      description: "Description",
      toDelete: false,
      isAdded: true,
    };

    feedPlan.subId = feedPlan.id;
    data.push(feedPlan);
    feedPlansData.push(feedPlan);

    setIsReadyForSave(false);
    setFeedPlans(data);
    setChangedRows(feedPlansData);
  };

  const importFeedPlans = (importedFeedPlans: FeedPlan[]): void => {
    let data = [...feedPlans];
    let feedPlansData = [...changedRows];
    importedFeedPlans = importedFeedPlans.map((feedPlan) => {
      feedPlan.id = generatedId(36);
      feedPlan.subId = feedPlan.id;
      feedPlan.isAdded = true;
      feedPlan.toDelete = false;
      return feedPlan;
    });

    data = data.concat(importedFeedPlans);
    feedPlansData = feedPlansData.concat(importedFeedPlans);

    setIsReadyForSave(false);
    setFeedPlans(data);
    setChangedRows(feedPlansData);
  };

  const save = (): void => {
    getFinalFeedPlans();

    const postData = async (): Promise<void> => {
      await post<FeedPlan[]>({ url: "/client/feed-plans/update", data: { data: changedRows } });

      setIsReadyForSave(true);
      setChangedRows([]);
      setActive(null);
      fetchFeedPlans("Successfully updated!");
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      handleErrorForFinalSetup();
    });
  };

  const handleErrorForFinalSetup = (): void => {
    let data = [...changedRows];

    data = data.map((feedPlan) => {
      if (feedPlan.isAdded && !feedPlan?.id) {
        feedPlan.id = feedPlan.subId;
      }

      return feedPlan;
    });

    setChangedRows(data);
  };

  const getFinalFeedPlans = (): void => {
    let data = [...changedRows];
    data = data.map((feedPlan) => {
      if (feedPlan.isAdded) {
        feedPlan.id = null;
      }
      return feedPlan;
    });
    setChangedRows(data);
  };

  const discardChanges = (): void => {
    setConfirmOpen(true);
    setActive(null);
  };

  const openImportFeedPlansModal = (): void => {
    setImportFeedPlanOpen(true);
  };

  const handleSetupFeedPlansComplete = (): void => {
    handleSetupComplete("FEED_PLANS", post, openSnackBarMessage, navigate);
  };

  return (
    <div className='system-setup'>
      <Header>
        <Tooltip title='Import Setup Feed Plans'>
          <IconButton onClick={openImportFeedPlansModal}>
            <FileUploadIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Button variant='contained' color='primary' onClick={handleSetupFeedPlansComplete}>
          Setup Complete
        </Button>
      </Header>

      <FeedPlansTable
        headers={headers}
        feedPlans={feedPlans}
        setFeedPlans={setFeedPlans}
        onAddChanged={onAddChanged}
        loading={loading}
        isUnsavedChanges={isReadyForSave}
        active={active}
        setActive={setActive}
        changedRows={changedRows}
      />

      <Footer
        onAdd={add}
        onSave={save}
        onRefresh={refresh}
        onCancel={discardChanges}
        saveButtonDisabled={isReadyForSave}
        cancelButtonDisabled={isReadyForSave}
        saveButtonLabel='Save System Feed Plans'
        addButtonLabel='Add System Feed Plans'
        refreshButtonLabel='Refresh System Feed Plans'
        cancelButtonLabel='Discard changes'
      />

      <ImportFeedPlanInstruction
        open={importFeedPlanOpen}
        setOpen={setImportFeedPlanOpen}
        uploadFeedPlans={validateFeedPlans}
      />
      <ValidationModal
        validationState={validationState}
        setValidationState={setValidationState}
        onAgree={() => uploadFeedPlans()}
      />

      <ConfirmDialog title='Discard Changes' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={cancel}>
        Are you sure you want to discard changes?
      </ConfirmDialog>
    </div>
  );
}
