/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Truck } from "../interfaces";
import { useSnackBar } from "../providers";
import useAxios from "./use-axios.hook";

export const useGetTrucks = () => {
  const [trucks, setTrucks] = useState<Truck[]>([]);

  const { get } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const fetchTrucks = async () => {
    try {
      const data = await get<Truck[]>({ url: "/client/trucks" });
      setTrucks(data);
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    }
  };

  useEffect(() => {
    fetchTrucks();
  }, []);

  return { trucks };
};
