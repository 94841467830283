/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { UnitContext } from "../../../../../contexts/unit-context";
import { CompanyUnit } from "../../../../../interfaces";

interface Props {
  active: boolean | undefined;
  payload: Payload<ValueType, NameType>[] | undefined;
  label: any;
  type: "weight" | "count";
}

export const CustomTooltip = ({ active, payload, label, type }: Props) => {
  const { unitOfMeasurement } = useContext(UnitContext);

  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: "white", padding: "12px 8px", border: "1.5px solid #cccccc", gap: "4px" }}>
        {payload.map((item, index) => {
          const itemToDisplay = unitOfMeasurement === CompanyUnit.Imperial ? `${item.value} lbs` : `${item.value} kg`;

          return (
            <div key={index}>
              <Typography sx={{ fontSize: "16px", color: "black" }}>{label}</Typography>
              <Typography sx={{ color: "#4a8d6b", fontSize: "17px" }}>
                {item.dataKey}: {type === "weight" ? itemToDisplay : item.value}
              </Typography>
            </div>
          );
        })}
      </Box>
    );
  }

  return null;
};
