import { AxiosProvider, Compose, SnackbarProvider } from "./providers";

import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { environment } from "./environments/environment.prod";
import reportWebVitals from "./reportWebVitals";
import { FullscreenProvider } from "./providers/full-screen-provider";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Compose providers={[[AxiosProvider, { config: { baseURL: environment.apiBaseUrl } }], SnackbarProvider]}>
          <FullscreenProvider>
            <CssBaseline />
            <App />
          </FullscreenProvider>
        </Compose>
      </Router>
    </ThemeProvider>
    ,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
