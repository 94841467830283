import "./App.scss";

import { Navigate, Route, Routes } from "react-router-dom";

import { BinManagement } from "./pages/bin-management/bin-management";
import { FeedForecast } from "./pages/feed-forecast/feed-forecast";
import { FeedGroupManagement } from "./pages/feed-group-management/feed-group-management";
import { FeedOrders } from "./pages/feed-orders/feed-orders";
import { Home } from "./pages/home/home";
import { Layout } from "./components/layout/layout";
import { Login } from "./pages/login/login";
import { ManagementDashboard } from "./pages/management-dashboard/management-dashboard";
import { SetupAnimals } from "./pages/system-setup/setup-animals/setup-animals";
import { SetupBines } from "./pages/system-setup/setup-bins/setup-bins";
import { SetupFeedGroups } from "./pages/system-setup/setup-feed-groups/setup-feed-groups";
import { SetupGrowthAdjustments } from "./pages/system-setup/setup-growth-adjustments/setup-growth-adjustments";
import { SetupSites } from "./pages/system-setup/setup-sites/setup-sites";
import { SystemFeedPlans } from "./pages/system-setup/feed-plans/feed-plans";
import { SystemFeedTypes } from "./pages/system-setup/feed-types/feed-types";
import { SystemSetup } from "./pages/system-setup/system-setup";
import { UserAdmin } from "./pages/user-admin/user-admin";
import { SetupBarns } from "./pages/system-setup/setup-barns/setup-barns";
import { SetupGrowthCurves } from "./pages/system-setup/setup-growth-curves/setup-growth-curves";
import { FeedPlanSequence } from "./pages/system-setup/feed-plans/feed-plan-sequence/feed-plan-sequence";
import { GrowthCurvesSequence } from "./pages/system-setup/setup-growth-curves/growth-curves-sequence/growth-curves-sequence";
import { GrowthAdjustmentConditions } from "./pages/system-setup/setup-growth-adjustments/growth-adjustment-conditions/growth-adjustment-conditions";
import { FeedPlanSummary } from "./pages/feed-group-management/feed-plan-summary/feed-plan-summary";
import { useFullscreen } from "./providers/full-screen-provider";
import { ForgotPassword } from "./pages/forgot-password/forgot-password";
import storage from "./utils/storage";
import { ResetPassword } from "./pages/reset-password/reset-password";
import { FeedLoadDetails } from "./pages/feed-orders/feed-load/feed-load";
import { SetupSiteCombinations } from "./pages/system-setup/site-combinations/site-combinations";
import { SetupMills } from "./pages/system-setup/setup-mills/setup-mills";
import { FeedGroupDetailScreen } from "./pages/feed-group-detail-screen/feed-group-detail-screen";
import { ProductionSequence } from "./pages/production-sequence/production-sequence";
import { SetupDrivers } from "./pages/system-setup/drivers/drivers";
import { SetupTrucks } from "./pages/system-setup/trucks/trucks";
import { LoadManagementScreen } from "./pages/load-management/load-management";
import { SetupUnit } from "./pages/system-setup/setup-units/setup-units";
import { UnitContextProvider } from "./contexts/unit-context";
import { BinInventoryHistory } from "./pages/bin-inventory-history/bin-inventory-history";

function App() {
  const { fullscreenRef } = useFullscreen();
  const token = storage.get("accessToken");

  return (
    <div ref={fullscreenRef}>
      {!token && (
        <Routes>
          <Route path='/login' element={<Login />} />

          <Route path='/forgot-password' element={<ForgotPassword />} />

          <Route path='/reset-password' element={<ResetPassword />} />

          <Route path='*' element={<Navigate to='/login' replace />} />
        </Routes>
      )}

      {!!token && (
        <UnitContextProvider>
          <Layout>
            <Routes>
              <Route path='/home' element={<Home />} />
              <Route path='/system-setup' element={<Navigate to='/system-setup/welcome' replace />} />
              <Route path='/system-setup/welcome' element={<SystemSetup />} />
              <Route path='/system-setup/units-of-measurement' element={<SetupUnit />} />
              <Route path='/system-setup/feed-plans' element={<SystemFeedPlans />} />
              <Route path='/system-setup/feed-plans-sequence/:id' element={<FeedPlanSequence />} />
              <Route path='/system-setup/animals' element={<SetupAnimals />} />
              <Route path='/system-setup/feed-types' element={<SystemFeedTypes />} />
              <Route path='/system-setup/feed-groups' element={<SetupFeedGroups />} />
              <Route path='/system-setup/bins' element={<SetupBines />} />
              <Route path='/system-setup/sites' element={<SetupSites />} />
              <Route path='/system-setup/barns' element={<SetupBarns />} />
              <Route path='/system-setup/growth-curves' element={<SetupGrowthCurves />} />
              <Route path='/system-setup/growth-curves-sequence/:id' element={<GrowthCurvesSequence />} />
              <Route path='/system-setup/growth-adjustments' element={<SetupGrowthAdjustments />} />
              <Route
                path='/system-setup/setup-growth-adjustments-conditions/:id'
                element={<GrowthAdjustmentConditions />}
              />
              <Route path='/system-setup/site-combinations' element={<SetupSiteCombinations />} />
              <Route path='/system-setup/mills' element={<SetupMills />} />
              <Route path='/system-setup/drivers' element={<SetupDrivers />} />
              <Route path='/system-setup/trucks' element={<SetupTrucks />} />
              <Route path='/bin-management' element={<BinManagement />} />
              <Route path='/bin-management/bin-inventory-history/:id' element={<BinInventoryHistory />} />
              <Route path='/feed-group-management' element={<FeedGroupManagement />} />
              <Route path='/feed-group-management/feed-plan-summary/:id' element={<FeedPlanSummary />} />
              <Route path='/feed-forecast' element={<FeedForecast />} />
              <Route path='/feed-forecast/feed-group-detail/:id' element={<FeedGroupDetailScreen />} />
              <Route path='/feed-orders' element={<FeedOrders />} />
              <Route path='/feed-orders/feed-load/:id' element={<FeedLoadDetails />} />
              <Route path='/management-dashboard' element={<ManagementDashboard />} />
              <Route path='/user-admin' element={<UserAdmin />} />
              <Route path='/production-sequence' element={<ProductionSequence />} />
              <Route path='/load-management' element={<LoadManagementScreen />} />
              <Route path='*' element={<Navigate to='/home' replace />} />
            </Routes>
          </Layout>
        </UnitContextProvider>
      )}
    </div>
  );
}

export default App;
