import { Grid } from "@mui/material";
import { CardManagement } from "../../../../config/card-management";
import { ManagementDashboardContentCard } from "./management-dashboard-content-card/management-dashboard-content-card";
import "./management-dashboard-content-cards.scss";

interface ManagementDashboardProps {
  rows: CardManagement[];
}

export function ManagementDashboardContentCards({ rows }: ManagementDashboardProps): JSX.Element {
  return (
    <div className='management-dashboard-content-cards'>
      <Grid container spacing={2}>
        {rows &&
          rows.map((row) => (
            <Grid item xs={4} key={row.id}>
              <p className='management-dashboard-content-cards__title'>{row.title}</p>
              <div className='management-dashboard-content-cards__card'>
                {row.cards &&
                  row.cards.map((card, index) => (
                    <div
                      className={`management-dashboard-content-cards__card-body ${
                        index === 0 && "management-dashboard-content-cards__card-body-first-child"
                      } ${
                        row.cards.length - 1 === index && "management-dashboard-content-cards__card-body-last-child"
                      }`}
                      key={card.id}
                    >
                      <card.Icon fontSize='large' />
                      <div className='management-dashboard-content-cards__items'>
                        {card.items &&
                          card.items.map((item, i) => (
                            <ManagementDashboardContentCard
                              onClick={item.action}
                              key={item.label}
                              item={item}
                              index={i}
                            />
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
