import { SidebarMenuItem } from "../interfaces";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import CalculateOutlined from "@mui/icons-material/CalculateOutlined";
import FoodBankOutlined from "@mui/icons-material/FoodBankOutlined";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import TimelineOutlined from "@mui/icons-material/TimelineOutlined";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import PersonAddAlt1Outlined from "@mui/icons-material/PersonAddAlt1Outlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

export const menuItems: SidebarMenuItem[] = [
  {
    label: "Home",
    link: "/home",
    expandable: false,
    icon: HomeOutlinedIcon,
  },
  {
    label: "System Setup",
    link: "/system-setup",
    expandable: true,
    icon: SettingsOutlined,
    expandableItems: [
      {
        label: "Welcome",
        link: "/system-setup/welcome",
      },
      {
        label: "Setup Unit",
        link: "/system-setup/units-of-measurement",
        tooltip: "Set system units as ‘Imperial’ or 'Metric",
      },
      {
        label: "Setup Mills",
        link: "/system-setup/mills",
        tooltip: "Define and setup all feed mills that will be managed through feedplannr",
      },
      {
        label: "Setup Feed Types",
        link: "/system-setup/feed-types",
        tooltip: "Define and setup all feed types to ensure correct weights and densities",
      },
      {
        label: "Setup Feed Plans",
        link: "/system-setup/feed-plans",
        tooltip: "Define and setup stage feeding plans for your animals",
      },
      {
        label: "Setup Growth Curves",
        link: "/system-setup/growth-curves",
        tooltip:
          "Define and setup different levels of growth and consumption to be used for forecasting growth and consumption for different animals",
      },
      {
        label: "Setup Animals",
        link: "/system-setup/animals",
        tooltip: "Define and setup different animals and animal sub-types that can each be setup uniquely",
      },
      {
        label: "Setup Growth Adjustments",
        link: "/system-setup/growth-adjustments",
      },
      {
        label: "Setup Sites",
        link: "/system-setup/sites",
        tooltip: "Define and setup all your Sites to ensure correct load sizes are sent to maximize fleet efficiency",
      },
      {
        label: "Setup Barns",
        link: "/system-setup/barns",
        tooltip: "Define and setup all your Barns to ensure they are associated with the correct Site",
      },
      {
        label: "Setup Bins",
        link: "/system-setup/bins",
        tooltip:
          "Define and setup all your Bins to ensure they are the correct size and inventory and are associated with the correct barn",
      },
      {
        label: "Setup Feed Groups",
        link: "/system-setup/feed-groups",
        tooltip:
          "Define and setup all your Feed groups to ensure that have the correct animals, correct count , correct age, correct consumption and correct growth for accurate forecasting",
      },
      {
        label: "Setup Site Combinations",
        link: "/system-setup/site-combinations",
        tooltip:
          "Define which Sites can be used in combination with each other so that we can avoid sending partial loads of feed to sites with low capacity",
      },
      {
        label: "Setup Drivers",
        link: "/system-setup/drivers",
        tooltip: "Setup list of drivers that can be used in logistics planning and scheduling",
      },
      {
        label: "Setup Trucks",
        link: "/system-setup/trucks",
        tooltip: "Setup list of trucks that can be used in logistics planning and scheduling",
      },
    ],
  },
  {
    label: "Bin Management",
    link: "/bin-management",
    expandable: false,
    icon: CalculateOutlined,
  },
  {
    label: "Feed Group Management",
    link: "/feed-group-management",
    expandable: false,
    icon: FoodBankOutlined,
  },
  {
    label: "Feed Forecast",
    link: "/feed-forecast",
    expandable: false,
    icon: AccessTimeOutlined,
  },
  {
    label: "Feed Orders",
    link: "/feed-orders",
    expandable: false,
    icon: TimelineOutlined,
  },
  {
    label: "User Admin",
    link: "/user-admin",
    expandable: false,
    icon: PersonAddAlt1Outlined,
  },
  {
    label: "Production Sequence",
    link: "/production-sequence",
    expandable: false,
    icon: ManageHistoryIcon,
  },
  {
    label: "Load Management",
    link: "/load-management",
    expandable: false,
    icon: AssuredWorkloadIcon,
  },
  {
    label: "Management Dashboard",
    link: "/management-dashboard",
    expandable: false,
    icon: InventoryOutlined,
  },
];
