import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import Button from "@mui/material/Button";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import "./setup-feed-groups-table.scss";
import { AnimalType, Bin, FeedGroup, FeedPlan, GrowthCurve, Site } from "../../../../interfaces";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import { EditableDatePickerCell } from "../../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import { EditableMultipleSelectCell } from "../../../../components/table/editable-multiple-select/editable-multiple-select";
import AnimalMovementModal from "../../../../components/animal-movement-dialog/animal-movement-dialog";

interface SetupFeedGroupsTableProps {
  headers: string[];
  rows: FeedGroup[];
  loading: boolean;
  setFeedGroups: Dispatch<SetStateAction<FeedGroup[]>>;
  onAddChanged: (row: FeedGroup, isDeleted?: boolean, isModified?: boolean) => void;
  fetchFeedGroups: () => void;
  isUnsavedChanges: boolean;
}

export function SetupFeedGroupsTable({
  headers,
  rows,
  loading,
  setFeedGroups,
  onAddChanged,
  fetchFeedGroups,
  isUnsavedChanges,
}: SetupFeedGroupsTableProps): JSX.Element {
  const [feedPlans, setFeedPlans] = useState<FeedPlan[] | []>([]);
  const [sites, setSites] = useState<Site[] | []>([]);
  const [growthCurves, setGrowthCurves] = useState<GrowthCurve[] | []>([]);
  const [animalTypes, setAnimalTypes] = useState<AnimalType[] | []>([]);
  const [animalMovementOpen, setAnimalMovementOpen] = useState(false);
  const [animalCount, setAnimalCount] = useState(0);
  const [feedGroupId, setFeedGroupId] = useState("");
  const [startDate, setStartDate] = useState<string | Date>("");

  const { openSnackBarMessage } = useSnackBar();
  const { get } = useAxios();

  useEffect(() => {
    handleFeedGroupSites();
    handleFeedGroupFeedPlans();
    handleFeedGroupGrowthCurves();
    handleAnimalSubtypesGroupedByTypes();
  }, []);

  const handleAnimalSubtypesGroupedByTypes = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<AnimalType[]>({ url: "/client/animal-types" });

      setAnimalTypes(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedGroupGrowthCurves = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<GrowthCurve[]>({ url: "/client/growth-curves" });

      setGrowthCurves(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedGroupSites = async (): Promise<void> => {
    const fetchData = async (): Promise<void> => {
      const data = await get<Site[]>({ url: "/client/sites" });

      setSites(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleFeedGroupFeedPlans = (): void => {
    const fetchData = async (): Promise<void> => {
      const data = await get<FeedPlan[]>({ url: "/client/feed-plans" });

      setFeedPlans(data);
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const onSaveChanges = (row: FeedGroup): void => {
    let data = [...rows];
    data = data.map((feedGroup) => {
      if (feedGroup.id === row.id) {
        feedGroup = row;
        onAddChanged(row, false, true);
      }
      return feedGroup;
    });
    setFeedGroups(data);
  };

  const removeFeedGroup = (row: FeedGroup): void => {
    let data = [...rows];
    const copyRow = { ...row };
    if (row?.id) {
      data = data.filter((feedGroup) => feedGroup.id !== copyRow?.id);
      setFeedGroups(data);
      onAddChanged(copyRow, true);
    }
  };

  const getBinsNames = (bins: Bin[]): string[] => {
    return bins?.map((bin) => bin?.name);
  };

  const handleOpenModal = (count: number, id: string | null, startDate: string | Date): void => {
    setAnimalCount(count);
    setStartDate(startDate);
    if (id) {
      setFeedGroupId(id);
    }
    setAnimalMovementOpen(true);
  };

  const onChangeSite = (siteId: string, id: string): void => {
    const data = [...rows];
    const site = sites?.find((site) => site?.id === siteId);
    const barns = site ? site.barns : [];
    data.forEach((bin) => {
      if (bin.id === id && barns) {
        if (bin?.site?.barns) {
          bin.site.barns = barns;
        } else {
          bin.site = {
            ...bin.site,
            barns,
          };
        }
        if (barns[0]?.id) {
          bin.barnId = barns[0].id;
          const bins = barns[0].bins;
          if (bin?.barn?.bins) {
            bin.barn.bins = bins;
          } else {
            bin.barn = {
              ...bin.barn,
              bins,
            };
          }
          bin.bins = bins;
        }
        onSaveChanges(bin);
      }
    });
  };

  const onChangeBarn = (barnId: string, id: string): void => {
    const data = [...rows];
    let bins: Bin[] = [];
    sites.forEach((site) => {
      const barn = site?.barns?.find((barn) => barn?.id === barnId);
      if (barn) {
        bins = barn?.bins;
      }
    });
    data.forEach((bin) => {
      if (bin.id === id && bins) {
        if (bin?.barn?.bins) {
          bin.barn.bins = bins;
        } else {
          bin.barn = {
            ...bin.barn,
            bins,
          };
        }
        bin.bins = bins;
        bin.binIds = bins?.map((binItem) => binItem?.id);
        onSaveChanges(bin);
      }
    });
  };

  const onChangeAnimalType = (animalTypeId: string, id: string): void => {
    const data = [...rows];
    const animalType = animalTypes.find((type) => type.id === animalTypeId);

    data.map((feedGroup) => {
      if (feedGroup.id === id && animalType?.subtypes[0]?.id) {
        feedGroup.animalType = animalType;
        feedGroup.animalSubtypeId = animalType.subtypes[0].id;
        feedGroup.animalSubtype = animalType.subtypes[0];
        onAddChanged(feedGroup);
      }

      return feedGroup;
    });
  };

  const onChangeAnimalSubtype = (animalSubtypeId: string, rowId: string): void => {
    const data = [...rows];
    const animalType = animalTypes.find((animalType) => {
      return animalType.subtypes?.find((sub) => {
        return sub.id === animalSubtypeId;
      });
    });
    const animalSubtype = animalType?.subtypes.find((subType) => subType.id === animalSubtypeId);

    data.map((feedGroup) => {
      if (feedGroup.id === rowId && animalSubtype) {
        feedGroup.avgWeightVariance = animalSubtype.defaultAvgWeightVariance;
        feedGroup.growthCurveId = animalSubtype.defaultGrowthCurveId;
        feedGroup.mortalityRate = animalSubtype.defaultMortalityRate;
        feedGroup.startingAge = animalSubtype.defaultStartingAge;
        feedGroup.startingWeight = animalSubtype.defaultStartingWeight;
        onSaveChanges(feedGroup);
      }

      return feedGroup;
    });
  };

  const handleAnimalSubtypes = (animalTypeId: string): { id: string; name: string }[] | undefined => {
    const animalType = animalTypes.find((type) => type.id === animalTypeId);
    const subtypes = animalType?.subtypes?.map((subtype) => ({ id: subtype.id, name: subtype.subtype }));

    return subtypes;
  };

  return (
    <section className='setup-feed-groups-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading} isUnsavedChanges={!isUnsavedChanges}>
        {rows.map((row, index) => (
          <StyledTableRow key={`${row.id}_${index}`}>
            <EditableTableCell
              row={row}
              data={row.name}
              property='name'
              name='Name'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Feed Plan'
              data={row.feedPlanId}
              property='feedPlanId'
              menuItems={feedPlans}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Site'
              data={row.siteId}
              property='siteId'
              menuItems={sites}
              isUnsavedChanges={isUnsavedChanges}
              onChangeValue={onChangeSite}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Barn'
              data={row?.barnId}
              property='barnId'
              menuItems={row?.site?.barns}
              isUnsavedChanges={isUnsavedChanges}
              onChangeValue={onChangeBarn}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Animal Type'
              data={row?.animalTypeId}
              property='animalTypeId'
              menuItems={animalTypes}
              isUnsavedChanges={isUnsavedChanges}
              onChangeValue={onChangeAnimalType}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Animal Subtype'
              data={row?.animalSubtypeId}
              property='animalSubtypeId'
              menuItems={handleAnimalSubtypes(row?.animalTypeId)}
              onChangeValue={onChangeAnimalSubtype}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.count}
              property='count'
              name='Count'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableDatePickerCell
              row={row}
              name='Start Date'
              data={new Date(row.startDate)}
              property='startDate'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableMultipleSelectCell
              row={row}
              name='Bins'
              data={getBinsNames(row.bins)}
              property='binIds'
              valueProperty='bins'
              menuItems={row?.barn?.bins}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={row}
              name='Growth Curve'
              data={row.growthCurveId}
              property='growthCurveId'
              menuItems={growthCurves}
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.startingWeight}
              property='startingWeight'
              name='Starting Weight'
              allowsNull
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.startingAge}
              property='startingAge'
              name='Starting Age'
              allowsNull
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.avgWeightVariance}
              property='avgWeightVariance'
              name='Avg Weight Variance'
              allowsNull
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableTableCell
              row={row}
              data={row.mortalityRate}
              min={0}
              max={1}
              allowsNull
              errorText='Default mortality rate must be at least 0 and cannot exceed 1'
              property='mortalityRate'
              name='Mortality Rate'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <EditableDatePickerCell
              row={row}
              name='End Date'
              minDate={new Date(row.startDate)}
              data={row.endDate ? new Date(row.endDate) : null}
              property='endDate'
              isUnsavedChanges={isUnsavedChanges}
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<EditIcon />}
                onClick={() => handleOpenModal(row.count, row.id, row.startDate)}
              >
                Edit
              </Button>
            </StyledTableCell>
            <StyledTableCell align='center'>
              <Button
                variant='contained'
                color='error'
                startIcon={<CloseOutlined />}
                onClick={() => removeFeedGroup(row)}
              >
                Remove
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomizedTable>
      {animalMovementOpen && (
        <AnimalMovementModal
          startDate={startDate}
          open={animalMovementOpen}
          setOpen={setAnimalMovementOpen}
          id={feedGroupId}
          count={animalCount}
          fetchItems={fetchFeedGroups}
        />
      )}
    </section>
  );
}
