import { useEffect, useRef, useState } from "react";
import { useDoubleClick, useOnClickOutside } from "../../../hooks";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledTableCell } from "../table";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import "./editable-datepicker-cell.scss";

const adapter = new AdapterDayjs();

interface EditableDatePickerCellProps<T> {
  row: T;
  data: Date | string | null;
  name: string;
  property: string;
  minDate?: Date;
  maxDate?: Date;
  isUnsavedChanges?: boolean;
  onSaveChanges?: (row: T) => void;
  onFocus?: () => void | undefined;
  onBlur?: () => void | undefined;
}

export function EditableDatePickerCell({
  row,
  data,
  name,
  property,
  minDate,
  maxDate,
  isUnsavedChanges,
  onSaveChanges,
  onFocus,
  onBlur,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
EditableDatePickerCellProps<any>): JSX.Element {
  const [isEditable, setIsEditable] = useState(false);
  const [date, setDate] = useState(data);
  const [isOpen, setIsOpen] = useState(false);
  const [isModified, setIsModified] = useState(row?.isAdded);
  const ref = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (!data) {
      setDate(null);
    }

    if (typeof data === "string" && data?.toString().includes("T")) {
      setDate(new Date(data.toString().replace("T", " ")));
    } else {
      setDate(data);
    }

    if (!row?.isAdded) {
      if (isModified && isUnsavedChanges) {
        setIsModified(false);
      }
    }
  }, [data, isUnsavedChanges]);

  const hybridClick = useDoubleClick(
    () => handleDoubleRowClick(),
    () => handleRowClick(),
  );

  const handleDoubleRowClick = (): void => {
    if (onSaveChanges && !isEditable && !hovered) {
      setIsEditable(!isEditable);
    }
  };

  const handleRowClick = (): void => {
    console.log("row click");
  };

  const onHandleClickOutside = (): void => {
    row[property] = date;
    if (!isOpen && onSaveChanges) {
      onSaveChanges(row);
      setIsEditable(false);
    }
  };

  useOnClickOutside(ref, onHandleClickOutside);

  const onChange = (dateTime: Date | null): void => {
    if (onSaveChanges) {
      row[property] = dateTime;
      onSaveChanges(row);
      setIsModified(true);
      setDate(dateTime);
    }
  };

  const onClose = (): void => {
    setIsEditable(false);
    setHovered(false);
    setIsOpen(false);
  };

  const onOpen = (): void => {
    setIsOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMinMaxAdapterDate = (date: Date | undefined): Dayjs | undefined | any => {
    return date ? adapter.date(new Date(date)) : undefined;
  };

  return (
    <StyledTableCell
      focusable
      align='center'
      className={`${isModified && "modified"} editable-field`}
      onClick={hybridClick}
    >
      {isEditable ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={name}
            ref={ref}
            value={date ? new Date(date) : new Date()}
            closeOnSelect={false}
            onClose={onClose}
            onOpen={onOpen}
            maxDate={getMinMaxAdapterDate(maxDate)}
            minDate={getMinMaxAdapterDate(minDate)}
            onChange={(value: Date | null) => onChange(value)}
            disabled={!isEditable}
            renderInput={(params) => <TextField {...params} />}
            InputProps={{
              onMouseOver: () => setHovered(true),
              onMouseLeave: () => setHovered(false),
              onFocus: () => {
                onFocus && onFocus();
              },
              onBlur: () => {
                onBlur && onBlur();
              },
            }}
            componentsProps={{
              actionBar: {
                actions: ["accept", "cancel"],
              },
            }}
          />
        </LocalizationProvider>
      ) : date ? (
        dayjs(date.toString().replace("Z", "")).format("MM/DD/YYYY")
      ) : (
        <span></span>
      )}
    </StyledTableCell>
  );
}
