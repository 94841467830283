import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../components/table/table";
import { FeedLoad, LoadManagement, Order, Status } from "../../../interfaces";
import { EditableSelectCell } from "../../../components/table/editable-select-cell/editable-select-cell";
import { useState } from "react";
import ScheduleModal from "../schedule-modal/schedule-modal";
import { useAxios, useGetDrivers, useGetTrucks } from "../../../hooks";
import { useSnackBar } from "../../../providers";
import { DeliveryModal } from "../delivery-modal/delivery-modal";
import { Link } from "react-router-dom";
import "./load-management-table.scss";
import { EditableDateTimePickerCell } from "../../../components/table/editable-datetimepicker-cell/editable-datetimepicker-cell";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface FeedGroupManagementTableProps {
  headers: string[];
  rows: LoadManagement[];
  loading: boolean;
  editFeedLoadManagement: (id: string, driverId: string, truckId: string, expectedDeliveryTime: Date | string) => void;
  refresh: (message?: string) => void;
}
const data = [
  {
    id: "SCHEDULED",
    name: "SCHEDULED",
  },
  {
    id: "PRODUCED",
    name: "PRODUCED",
  },
  {
    id: "DELIVERED",
    name: "DELIVERED",
  },
];

export function LoadManagementTable({
  headers,
  rows,
  loading,
  refresh,
  editFeedLoadManagement,
}: FeedGroupManagementTableProps): JSX.Element {
  const [openScheduleModal, setScheduleModalOpen] = useState(false);
  const [openDeliveryModal, setDeliveryModalOpen] = useState(false);
  const [row, setRow] = useState<LoadManagement>();
  const [feedLoad, setFeedLoad] = useState<FeedLoad>({} as FeedLoad);
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { trucks } = useGetTrucks();
  const { drivers } = useGetDrivers();
  const { get, patch } = useAxios();
  const { openSnackBarMessage } = useSnackBar();

  const isDisabled = (status: Status) => {
    if (rows && status !== "ORDERED" && status !== "PENDING" && status !== "PLANNED") {
      return false;
    }

    return true;
  };

  const fetchFeedLoad = async (id: string) => {
    setIsLoading(true);

    try {
      const response = await get<FeedLoad>({ url: `/client/feed-orders/${id}` });

      setFeedLoad(response);
      setOrders(response.orders);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setIsLoading(false);
    }
  };

  const handleStatusChanged = async (id: string) => {
    try {
      await patch({ url: `/client/feed/loads/${id}/status/produced` });

      refresh("Successfully changed status to Produced");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
    }
  };

  const onChangeStatus = (value: string, row: LoadManagement) => {
    if (value === "SCHEDULED") {
      setScheduleModalOpen(true);
      setRow(row);
      return;
    }

    if (value === "DELIVERED") {
      setDeliveryModalOpen(true);
      fetchFeedLoad(row.id);
      return;
    }

    if (value === "PRODUCED") {
      handleStatusChanged(row.id);
      return;
    }
  };

  const onChangeTrackManagement = (value: string, row: LoadManagement): void => {
    const truck = trucks.find((item) => item.name === value);
    if (truck?.id) {
      editFeedLoadManagement(row.id, row.driverId, truck.id, row.expectedDeliveryTime);
    }
  };

  const onChangeDriverManagement = (value: string, row: LoadManagement): void => {
    const driver = drivers.find((item) => `${item.firstName} ${item.lastName}` === value);
    if (driver?.id) {
      editFeedLoadManagement(row.id, driver.id, row.truckId, row.expectedDeliveryTime);
    }
  };

  const onChangeExpectedDeliveryTime = (row: LoadManagement): void => {
    if (row?.expectedDeliveryTime) {
      const dateTime = dayjs(row.expectedDeliveryTime).utc().format("MM/DD/YYYY hh:mm:ss");
      editFeedLoadManagement(row.id, row.driverId, row.truckId, dateTime);
    }
  };

  return (
    <section className='feed-group-management-table'>
      <CustomizedTable headers={headers} isEmpty={!rows?.length} loading={loading}>
        {rows.map((row, index) => {
          return (
            <StyledTableRow key={`${row.id}_${index}`}>
              <StyledTableCell align='center' component='th' scope='row'>
                <Link to={`/feed-orders/feed-load/${row.id}`} className='feed-group-management-table__link'>
                  {row.number}
                </Link>
              </StyledTableCell>
              <StyledTableCell align='center'>{row.totalAmount}</StyledTableCell>
              <EditableDateTimePickerCell
                row={row}
                data={row.expectedDeliveryTime}
                name='Expected Delivery Time'
                property='expectedDeliveryTime'
                onSaveChanges={onChangeExpectedDeliveryTime}
              />
              {isDisabled(row.status) ? (
                <StyledTableCell align='center'>{row.status}</StyledTableCell>
              ) : (
                <EditableSelectCell
                  row={row}
                  name='Status'
                  data={row.status}
                  property='status'
                  menuItems={data}
                  onItemPress={(value) => onChangeStatus(value, row)}
                />
              )}
              <StyledTableCell align='center'>
                {row.sites.length === 1 ? row.sites[0].name : "Multiply"}
              </StyledTableCell>
              <EditableSelectCell
                row={row}
                name='Driver'
                data={row.driverId}
                property='driverId'
                menuItems={drivers.map((driver) => ({ id: driver.id, name: `${driver.firstName} ${driver.lastName}` }))}
                onItemPress={(value) => onChangeDriverManagement(value, row)}
              />
              <EditableSelectCell
                row={row}
                name='Truck'
                data={row.truckId}
                property='truckId'
                menuItems={trucks}
                onItemPress={(value) => onChangeTrackManagement(value, row)}
              />
            </StyledTableRow>
          );
        })}
      </CustomizedTable>

      <ScheduleModal
        open={openScheduleModal}
        setOpen={setScheduleModalOpen}
        row={row}
        drivers={drivers}
        trucks={trucks}
        refresh={refresh}
      />

      <DeliveryModal
        open={openDeliveryModal}
        setOpen={setDeliveryModalOpen}
        setFeedLoadOrders={setOrders}
        orders={orders}
        loading={isLoading}
        feedLoad={feedLoad}
        refresh={refresh}
      />
    </section>
  );
}
