import "./create-feed-load-modal.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { EditableTableCell } from "../../../../components/table/editable-input-cell/editable-input-cell";
import { EditableSelectCell } from "../../../../components/table/editable-select-cell/editable-select-cell";
import CustomizedTable, { StyledTableCell, StyledTableRow } from "../../../../components/table/table";
import { useAxios } from "../../../../hooks";
import { useSnackBar } from "../../../../providers";
import { Barn, Bin, Site } from "../../../../interfaces";
import { EditableDatePickerCell } from "../../../../components/table/editable-datepicker-cell/editable-datepicker-cell";
import dayjs from "dayjs";
interface FeedLoadModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchFeedLoad: (message?: string) => void;
  millId: string | undefined;
  lastOrderTime: Date | undefined;
  id: string | undefined;
}

interface FeedLoadData {
  siteId: string;
  barnId: string;
  binId: string;
  amount: number;
  requiredBy: Date | undefined;
}

const FeedLoadModal = ({ open, setOpen, fetchFeedLoad, millId, lastOrderTime, id }: FeedLoadModalProps) => {
  const theme = useTheme();
  const headers = ["Site", "Barn", "Bin", "Feed Group", "Amount", "Delivery Date"];
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [data, setData] = useState<FeedLoadData>({
    siteId: "",
    barnId: "",
    binId: "",
    amount: 0,
    requiredBy: lastOrderTime,
  });
  const [sites, setSites] = useState<Site[]>([]);
  const [bins, setBins] = useState<Bin[]>([]);
  const [barns, setBarns] = useState<Barn[]>([]);
  const [feedGroup, setFeedGroup] = useState("");

  const { openSnackBarMessage } = useSnackBar();
  const { post, get } = useAxios();

  useEffect(() => {
    fetchMillFeedGroup();
  }, [id]);

  const fetchMillFeedGroup = (): void => {
    const fetchData = async (): Promise<void> => {
      const response = await get<{ sites: Site[] }>({ url: `/client/mills/${millId}/feed-groups` });

      setSites(response?.sites);
      if (response?.sites?.length && response?.sites[0]?.id) {
        setData({ ...data, siteId: response.sites[0].id });
        const barns = response?.sites?.find((site) => site.id === response.sites[0].id)?.barns;
        if (barns) {
          setBarns(barns);
        }
      }
    };

    fetchData().catch((error) => openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error"));
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleCreate = (): void => {
    const postData = async (): Promise<void> => {
      await post<FeedLoadData[]>({
        url: `/client/feed-orders/${id}/add`,
        data: { binId: data.binId, amount: data.amount, requiredBy: dayjs(data.requiredBy).format("MM/DD/YYYY") },
      });

      fetchFeedLoad("Successfully created!");
      setOpen(false);
    };

    postData().catch((error) => {
      openSnackBarMessage(`${error?.response?.data?.message || error?.message}!`, "error");
      setOpen(false);
    });
  };

  const onSaveChanges = (data: FeedLoadData): void => {
    if (data.siteId) {
      const barns = sites.find((site) => site.id === data.siteId)?.barns;
      if (barns) {
        setBarns(barns);
      }
    }

    if (data.barnId) {
      const bins = barns.find((barn) => barn.id === data.barnId)?.bins;
      if (bins) {
        setBins(bins);
      }
    }

    if (data.binId) {
      const feedGroup = bins.find((bin) => bin.id === data.binId)?.feedGroup;
      if (feedGroup) {
        setFeedGroup(feedGroup.name);
      }
    }

    setData(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      aria-labelledby='responsive-dialog'
      className='responsive-dialog'
    >
      <DialogTitle id='responsive-dialog' className='responsive-dialog__title'>
        Update Record
      </DialogTitle>

      <DialogContent>
        <CustomizedTable headers={headers}>
          <StyledTableRow>
            <EditableSelectCell
              row={data}
              name='Site'
              menuItems={sites}
              data={data.siteId}
              property='siteId'
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={data}
              name='Barn'
              menuItems={barns}
              data={data.barnId}
              property='barnId'
              onSaveChanges={onSaveChanges}
            />
            <EditableSelectCell
              row={data}
              name='Bin'
              menuItems={bins}
              data={data.binId}
              property='binId'
              onSaveChanges={onSaveChanges}
            />
            <StyledTableCell>{feedGroup}</StyledTableCell>
            <EditableTableCell
              row={data}
              name='Amount'
              data={data.amount}
              property='amount'
              onSaveChanges={onSaveChanges}
            />
            <EditableDatePickerCell
              row={data}
              data={data.requiredBy ? new Date(data.requiredBy) : new Date()}
              name='Delivery date'
              property='requiredBy'
              onSaveChanges={onSaveChanges}
            />
          </StyledTableRow>
        </CustomizedTable>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button autoFocus onClick={handleCreate} className='responsive-dialog__button-update'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FeedLoadModal;
